/**
 * Set of platform feature flags that should be enabled by default on the entire atlassian.design website.
 */
export const featureFlagsPlatform = {
	// 'feature-flag-name': true,
	platform_nav4_scroll_into_view_refactor: true,
};

// featureFlags are feature flags the the user can enable through localstorage
// These are on the client-side
const featureFlags = {};

export type FeatureFlag = keyof typeof featureFlags;

export default featureFlags;
