/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { Code } from '@atlaskit/code';
import Link from '@atlaskit/link';
import { media, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

import SectionHeadingLink from '../section-link';

type Workspace = {
	repository?: string;
	name: string;
	group?: string;
	directoryName: string;
};

const headerStyles = css({
	boxSizing: 'border-box',
	minWidth: '8ch',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage
	color: token('color.text.subtlest'),
	fontWeight: token('font.weight.regular'),
});

const tBodyStyles = css({
	display: 'grid',
	columnGap: token('space.100'),
	borderBlockEnd: 'none',
	[media.above.sm]: {
		gridTemplateColumns: '2fr 1fr',
	},
});

/**
 * __Code docs header__
 *
 * A code docs header for the code page on atlassian design.
 */
const CodeDocsHeader = ({
	repository,
	name,
	group = 'design-system',
	directoryName,
}: Workspace) => {
	return (
		<Fragment>
			<SectionHeadingLink id="installation" depth={2}>
				Installation
			</SectionHeadingLink>
			<table>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
				<caption style={{ margin: 0 }}>
					<VisuallyHidden>Package installation information</VisuallyHidden>
				</caption>
				<tbody css={tBodyStyles}>
					<tr>
						<th scope="row" css={headerStyles}>
							<Text>Install</Text>
						</th>
						<td>
							<Code>yarn add {name}</Code>
						</td>
					</tr>
					{repository && (
						<tr>
							<th scope="row" css={headerStyles}>
								<Text>Source</Text>
							</th>
							<td>
								<Text weight="medium">
									<Link href={`${repository}/src/master/${group}/${directoryName}`} target="_blank">
										Bitbucket.org
									</Link>
								</Text>
							</td>
						</tr>
					)}
					<tr>
						<th scope="row" css={headerStyles}>
							<Text>npm</Text>
						</th>
						<td>
							<Text weight="medium">
								<Link href={`https://www.npmjs.com/package/${name}`} target="_blank">
									{name}
								</Link>
							</Text>
						</td>
					</tr>
					<tr>
						<th scope="row" css={headerStyles}>
							<Text>Bundle</Text>
						</th>
						<td>
							<Text weight="medium">
								<Link href={`https://unpkg.com/${name}/dist/`} target="_blank">
									unpkg.com
								</Link>
							</Text>
						</td>
					</tr>
				</tbody>
			</table>
		</Fragment>
	);
};

export default CodeDocsHeader;
