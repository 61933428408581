/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useCallback, useLayoutEffect, useState } from 'react';

import { jsx } from '@compiled/react';

import { Status } from '@af/design-system-docs-ui';
import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	MenuLinkItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
	SideNav,
	SideNavContent,
} from '@atlassian/navigation-system';

import { type NavigationItem, type NavigationItemData } from './types';
import { checkCurrentPath, checkCurrentPathAncestor } from './utils/check-path';

export const SideNavigation = ({
	navigation,
	currentPath,
}: {
	navigation: NavigationItem[];
	currentPath: string;
}) => {
	return (
		<SideNav>
			<SideNavContent>
				<RecursiveMenuItems navigation={navigation} currentPath={currentPath} />
			</SideNavContent>
		</SideNav>
	);
};

const RecursiveMenuItems = ({
	navigation,
	currentPath,
}: {
	navigation: NavigationItem[];
	currentPath: string;
}) => {
	return (
		<React.Fragment>
			{navigation.map(({ item, subitems }: NavigationItem) => {
				// Base case. Leaf node doesn't have any nested routes
				if (subitems.length === 0) {
					return (
						<MenuLinkItem
							elemAfter={item.status && <Status type={item.status.type} />}
							href={item.to || '/'}
							isSelected={checkCurrentPath(item.to, currentPath, subitems)}
							key={item.to || '/'}
						>
							{item.title}
						</MenuLinkItem>
					);
				}

				// Recursive case 1
				// If it doesn't have a `href`, then it's a component grouping
				// (like 'Primitives' or 'Layout and Structure')
				// So we can render it as a heading and group
				if (!item.to) {
					return (
						<MenuSection>
							<MenuSectionHeading key={item.title}>{item.title}</MenuSectionHeading>
							<MenuList>
								<RecursiveMenuItems navigation={subitems} currentPath={currentPath} />
							</MenuList>
						</MenuSection>
					);
				}

				// Recursive case 2
				// Else, render it as a collapsible menu item
				return (
					<ControlledExpandableMenuItem item={item} subitems={subitems} currentPath={currentPath} />
				);
			})}
		</React.Fragment>
	);
};

/**
 * Controlled state version of the ExpandableMenuItem from nav4.
 * We need to use controlled state so we can expand a parent menu item when a child is selected, based on the
 * current path.
 */
const ControlledExpandableMenuItem = ({
	item,
	subitems,
	currentPath,
}: {
	item: NavigationItemData;
	subitems: NavigationItem[];
	currentPath: string;
}) => {
	const [isExpanded, setIsExpanded] = useState(() => {
		if (!item.to) {
			return false;
		}

		return checkCurrentPathAncestor(item.to, currentPath);
	});

	const isSelected = checkCurrentPath(item.to, currentPath, subitems);

	/**
	 * Expand the parent menu item (the current item) when a child is selected.
	 * Using a layout effect to ensure the parent has expanded before the child menu item is scrolled into view.
	 */
	useLayoutEffect(() => {
		if (!item.to) {
			return;
		}

		if (isSelected) {
			// Exit early if the current item (expandable parent) is selected
			return;
		}

		// When a child becomes selected (e.g. the path changes), the parent should expand
		const isChildSelected = checkCurrentPathAncestor(item.to, currentPath);
		if (!isChildSelected) {
			return;
		}

		setIsExpanded(true);
	}, [currentPath, isSelected, item.to]);

	const handleExpansionToggle = useCallback((isExpanded: boolean) => {
		setIsExpanded(isExpanded);
	}, []);

	return (
		<ExpandableMenuItem
			isExpanded={isExpanded}
			key={item.to}
			onExpansionToggle={handleExpansionToggle}
		>
			<ExpandableMenuItemTrigger
				elemAfter={item.status && <Status type={item.status.type} />}
				href={item.to}
				isSelected={isSelected}
			>
				{item.title}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<MenuSection>
					<RecursiveMenuItems navigation={subitems} currentPath={currentPath} />
				</MenuSection>
			</ExpandableMenuItemContent>
		</ExpandableMenuItem>
	);
};
