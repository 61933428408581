import LogoIcon from "../../../../../../packages/design-system/logo/examples/constellation/logo-icon";
import LogoSmall from "../../../../../../packages/design-system/logo/examples/constellation/logo-small";
import LogoMedium from "../../../../../../packages/design-system/logo/examples/constellation/logo-medium";
import LogoLarge from "../../../../../../packages/design-system/logo/examples/constellation/logo-large";
import LogoBrand from "../../../../../../packages/design-system/logo/examples/constellation/logo-brand";
import LogoInverse from "../../../../../../packages/design-system/logo/examples/constellation/logo-inverse";
import LogoNeutral from "../../../../../../packages/design-system/logo/examples/constellation/logo-neutral";
import LogoDefault from "../../../../../../packages/design-system/logo/examples/constellation/logo-default";
import LogoAtlassian from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian";
import LogoAtlassianAcess from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-access";
import LogoAtlassianAdministration from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-administration";
import LogoAtlassianAdmin from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-admin";
import LogoAtlassianMarketplace from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-marketplace";
import LogoAtlassianStart from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-start";
import LogoAtlas from "../../../../../../packages/design-system/logo/examples/constellation/logo-atlas";
import LogoBitbucket from "../../../../../../packages/design-system/logo/examples/constellation/logo-bitbucket";
import LogoCompass from "../../../../../../packages/design-system/logo/examples/constellation/logo-compass";
import LogoConfluence from "../../../../../../packages/design-system/logo/examples/constellation/logo-confluence";
import LogoFocus from "../../../../../../packages/design-system/logo/examples/constellation/logo-focus";
import LogoGuard from "../../../../../../packages/design-system/logo/examples/constellation/logo-guard";
import LogoHalp from "../../../../../../packages/design-system/logo/examples/constellation/logo-halp";
import LogoJira from "../../../../../../packages/design-system/logo/examples/constellation/logo-jira";
import LogoJiraProductDiscovery from "../../../../../../packages/design-system/logo/examples/constellation/logo-jira-product-discovery";
import LogoJiraServiceManagement from "../../../../../../packages/design-system/logo/examples/constellation/logo-jira-service-management";
import LogoJiraSoftware from "../../../../../../packages/design-system/logo/examples/constellation/logo-jira-software";
import LogoJiraWorkManagement from "../../../../../../packages/design-system/logo/examples/constellation/logo-jira-work-management";
import LogoLoomAttribution from "../../../../../../packages/design-system/logo/examples/constellation/logo-loom-attribution";
import LogoLoom from "../../../../../../packages/design-system/logo/examples/constellation/logo-loom";
import LogoOpsgenie from "../../../../../../packages/design-system/logo/examples/constellation/logo-opsgenie";
import LogoRovo from "../../../../../../packages/design-system/logo/examples/constellation/logo-rovo";
import LogoStatuspage from "../../../../../../packages/design-system/logo/examples/constellation/logo-statuspage";
import LogoTrello from "../../../../../../packages/design-system/logo/examples/constellation/logo-trello";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_icon from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-icon";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_small from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-small";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_medium from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-medium";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_large from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-large";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_brand from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-brand";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_inverse from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-inverse";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_neutral from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-neutral";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_default from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_access from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-access";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_administration from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-administration";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_admin from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-admin";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_marketplace from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-marketplace";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_start from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlassian-start";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlas from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-atlas";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_bitbucket from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-bitbucket";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_compass from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-compass";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_confluence from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-confluence";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_focus from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-focus";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_guard from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-guard";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_halp from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-halp";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-jira";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_product_discovery from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-jira-product-discovery";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_service_management from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-jira-service-management";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_software from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-jira-software";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_work_management from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-jira-work-management";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_loom_attribution from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-loom-attribution";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_loom from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-loom";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_opsgenie from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-opsgenie";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_rovo from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-rovo";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_statuspage from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-statuspage";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_trello from "!!raw-loader!../../../../../../packages/design-system/logo/examples/constellation/logo-trello";
import SectionMessage from '@atlaskit/section-message';
import * as React from 'react';
export default {
  LogoIcon,
  LogoSmall,
  LogoMedium,
  LogoLarge,
  LogoBrand,
  LogoInverse,
  LogoNeutral,
  LogoDefault,
  LogoAtlassian,
  LogoAtlassianAcess,
  LogoAtlassianAdministration,
  LogoAtlassianAdmin,
  LogoAtlassianMarketplace,
  LogoAtlassianStart,
  LogoAtlas,
  LogoBitbucket,
  LogoCompass,
  LogoConfluence,
  LogoFocus,
  LogoGuard,
  LogoHalp,
  LogoJira,
  LogoJiraProductDiscovery,
  LogoJiraServiceManagement,
  LogoJiraSoftware,
  LogoJiraWorkManagement,
  LogoLoomAttribution,
  LogoLoom,
  LogoOpsgenie,
  LogoRovo,
  LogoStatuspage,
  LogoTrello,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_icon,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_small,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_medium,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_large,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_brand,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_inverse,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_neutral,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_access,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_administration,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_admin,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_marketplace,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlassian_start,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_atlas,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_bitbucket,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_compass,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_confluence,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_focus,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_guard,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_halp,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_product_discovery,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_service_management,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_software,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_jira_work_management,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_loom_attribution,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_loom,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_opsgenie,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_rovo,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_statuspage,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHlogo_trello,
  SectionMessage,
  React
};