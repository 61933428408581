/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch-dom';

import Button, { IconButton } from '@atlaskit/button/new';
import CloseIcon from '@atlaskit/icon/glyph/editor/close';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Hide, Show } from '@atlaskit/primitives/responsive';

import SearchClient from './client';
import SearchInput from './input';

interface SearchProps {
	isOpen: boolean;
	onClose(): void;
	testId?: string;
}

/**
 * __Search__
 */
const Search = ({ isOpen, onClose, testId }: SearchProps) => {
	const searchClient = algoliasearch(
		process.env.GATSBY_CONSTELLATION_ALGOLIA_APP_ID!,
		process.env.GATSBY_CONSTELLATION_ALGOLIA_SEARCH_KEY!,
	);

	return (
		<ModalTransition>
			{isOpen && (
				<InstantSearch indexName="Components" searchClient={searchClient}>
					<Modal
						onClose={onClose}
						shouldScrollInViewport={false}
						testId={testId ? `${testId}-modal` : undefined}
					>
						<ModalHeader>
							<SearchInput testId={testId} />
							<Hide above="md">
								<IconButton
									appearance="subtle"
									onClick={onClose}
									icon={CloseIcon}
									label="Close navigation"
								/>
							</Hide>
						</ModalHeader>
						<ModalBody>
							<SearchClient onResultClick={onClose} testId={testId} />
						</ModalBody>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
						<Show above="md">
							<ModalFooter>
								<Button appearance="subtle" onClick={onClose}>
									Close
								</Button>
							</ModalFooter>
						</Show>
					</Modal>
				</InstantSearch>
			)}
		</ModalTransition>
	);
};

export default Search;
