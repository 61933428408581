import type { NavigationItem } from '@af/design-system-docs-ui';

import { EMPTY_TITLE_PLACEHOLDER } from '../../utils/constants';
import { statusMapper } from '../../utils/status-mapper';

/**
 * __Get guideline page__
 *
 * Constructs a guideline page navigation hierarchy
 * with subitems for a single guideline page.
 */
const getGuidelinePage = ({
	guideline,
	category,
}: {
	guideline: Queries.ContentfulGuideline;
	category: {
		href: string;
		nodes: Queries.ContentfulGuideline[];
	};
}): NavigationItem => {
	const { slug, contentfulparent, title, status, readyForPreview } = guideline;

	return {
		item: {
			to:
				contentfulparent && contentfulparent.slug
					? `${category.href}/${contentfulparent.slug}/${slug}/`
					: `${category.href}/${slug}/`,
			title: title || EMPTY_TITLE_PLACEHOLDER,
			parent: contentfulparent?.slug,
			status: statusMapper({
				status,
				readyForPreview,
			}),
			slug,
		},
		subitems: category.nodes
			.filter(({ contentfulparent }) => contentfulparent?.slug === slug)
			.map((guideline) => {
				return getGuidelinePage({ guideline, category });
			}),
	};
};

/**
 * __Get guideline pages__
 *
 * Constructs a navigation hierarchy of guideline pages
 * based on content pages supplied from Gatsby.
 */
const getGuidelinePages = ({
	guidelines,
	slug,
}: {
	guidelines: Queries.ContentfulGuideline[];
	slug: string;
}): NavigationItem[] => {
	// Filter nodes by first level
	const firstLevelNodes = guidelines.filter(
		({ contentfulparent }: Queries.ContentfulGuideline) => contentfulparent === null,
	);

	const nodes = firstLevelNodes.map((guideline) =>
		getGuidelinePage({
			guideline,
			category: { href: `/${slug}`, nodes: guidelines },
		}),
	);

	// Add a custom "All design tokens" link to tokens guidelines
	if (slug === 'tokens') {
		nodes.push({
			item: {
				title: 'All design tokens',
				to: '/components/tokens/all-tokens',
			},
			subitems: [],
		});
	}

	return nodes;
};

export default getGuidelinePages;
