/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors */

/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { MDXProvider } from '@mdx-js/react';
import { useLocation } from '@reach/router';

import {
	DoDont,
	type DoDontProps,
	Example,
	NAVIGATION_BREAKPOINTS,
	SideNavigation,
	TopNavigation,
} from '@af/design-system-docs-ui';
import AppProvider from '@atlaskit/app-provider';
import { Code as Snippet } from '@atlaskit/code';
import { media } from '@atlaskit/primitives/responsive';
import { Main, Root as PageLayout } from '@atlassian/navigation-system';

import MetaTags from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/meta-tags';
import Props from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/props';
import PropsLogger from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/utilities/props-logger';
import useSideNavigation from '../hooks/use-side-navigation';

import AppProviderRouterLink from './app-provider-router-link';
import Footer from './footer';
import GlobalStyles from './global-styles';
import SearchModal from './search';
import Settings from './settings';
type LayoutProps = {
	children: any;
};

const innerContentStyles = css({
	height: '100%',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[NAVIGATION_BREAKPOINTS.MOBILE]: {
		isolation: 'isolate',
	},
	[media.above.sm]: {
		display: 'grid',
		gridTemplateAreas: `
      'hero hero'
      'content nav'
      'footer footer'
    `,
		gridTemplateColumns: 'minmax(25rem, 60rem) 1fr',
		gridTemplateRows: 'auto 1fr',
	},
});

const DoDontWrapper = ({ fullWidth, ...other }: DoDontProps & { fullWidth?: boolean }) => {
	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'grid', gridColumn: `span ${fullWidth ? '12' : '6'}` }}
		>
			<DoDont {...other} />
		</div>
	);
};

const shortcodes = {
	DoDont: DoDontWrapper,
	Example,
	PropsLogger,
	PropsTable: Props,
	Snippet,
} as const;

/**
 * __Layout__
 *
 * A layout for design system docs.
 *
 */
const Layout = ({ children }: LayoutProps) => {
	const navigation = useSideNavigation();
	const { pathname: currentPath } = useLocation();

	const isCustomThemeAdded = useRef(false);

	useEffect(() => {
		if (!currentPath.startsWith('/components') || isCustomThemeAdded.current) {
			return;
		}
		// A temporary workaround to make sub theme working on examples
		// to support custom attribute on DOMs [data-custom-mode][data-color-mode]
		const darkStyle = document.querySelector('style[data-theme="dark"]');
		const lightStyle = document.querySelector('style[data-theme="light"]');

		if (darkStyle?.textContent) {
			darkStyle.textContent = darkStyle.textContent.replace(
				'\nhtml[data-color-mode="light"][data-theme~="light:dark"],\nhtml[data-color-mode="dark"][data-theme~="dark:dark"]',
				'\nhtml[data-color-mode="light"][data-theme~="light:dark"],\nhtml[data-color-mode="dark"][data-theme~="dark:dark"], html [data-custom-mode="true"][data-color-mode="dark"]',
			);
		}
		if (lightStyle?.textContent) {
			lightStyle.textContent = lightStyle.textContent.replace(
				'\nhtml[data-color-mode="light"][data-theme~="light:light"],\nhtml[data-color-mode="dark"][data-theme~="dark:light"]',
				'\nhtml[data-color-mode="light"][data-theme~="light:light"], html [data-custom-mode="true"][data-color-mode="light"],\nhtml[data-color-mode="dark"][data-theme~="dark:light"]',
			);
		}
		isCustomThemeAdded.current = true;
	}, [currentPath]);

	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const handleSearchClose = useCallback(() => {
		setIsSearchOpen(false);
	}, []);
	const handleSearchOpen = useCallback(() => {
		setIsSearchOpen(true);
	}, []);

	return (
		<MDXProvider components={shortcodes}>
			<AppProvider
				defaultColorMode="light"
				defaultTheme={{
					dark: 'dark',
					light: 'light',
					shape: 'shape',
					spacing: 'spacing',
					typography: 'typography-modernized',
				}}
				routerLinkComponent={AppProviderRouterLink}
			>
				<GlobalStyles />
				<MetaTags isGlobal />
				<PageLayout>
					<TopNavigation
						navigation={navigation}
						onSearchOpen={handleSearchOpen}
						ThemeSwitcher={<Settings />}
					/>
					<SideNavigation navigation={navigation} currentPath={currentPath} />
					<Main>
						<div css={innerContentStyles}>
							{children}
							<Footer id="footer" />
						</div>
					</Main>
				</PageLayout>
				<SearchModal isOpen={isSearchOpen} onClose={handleSearchClose} />
			</AppProvider>
		</MDXProvider>
	);
};

export default Layout;
