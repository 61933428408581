import React from 'react';

import type { BasicDoc, Hit } from 'react-instantsearch-core';

import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { CATEGORY_TITLES } from '../../utils/constants';

import Highlight from './highlight';
import { LinkItem } from './items';

const iconColorMapping = {
	[CATEGORY_TITLES.COMPONENTS]: token('color.icon.accent.green', '#22A06B'),
	[CATEGORY_TITLES.TOKENS]: token('color.icon.accent.yellow', '#B38600'),
	[CATEGORY_TITLES.FOUNDATIONS]: token('color.icon.accent.teal', '#1D9AAA'),
	[CATEGORY_TITLES.RESOURCES]: token('color.icon.accent.magenta', '#CD519D'),
	[CATEGORY_TITLES.PATTERNS]: token('color.icon.accent.orange', '#D97008'),
	[CATEGORY_TITLES.CONTENT]: token('color.icon.accent.red', '#E34935'),
	[CATEGORY_TITLES.GET_STARTED]: token('color.icon.accent.purple', '#8270DB'),
};

type ResultItemProps = {
	onClick?: () => void;
	hit: Hit<BasicDoc>;
	indexName: string;
};

const dotStyles = xcss({
	height: '8px',
	width: '8px',
	marginBlockStart: 'space.075',
	borderRadius: 'border.radius.circle',
	flexShrink: 0,
});

/**
 * __Result item__
 */
const ResultItem = ({ indexName, hit, onClick }: ResultItemProps) => {
	let path = hit.path;
	const hasMatchInTitle =
		hit._highlightResult.title?.matchLevel && hit._highlightResult.title?.matchLevel !== 'none';
	let hasMatchInDescription =
		hit._highlightResult.description?.matchLevel &&
		hit._highlightResult.description?.matchLevel !== 'none';
	const hasMatchInContent =
		hit._highlightResult.content?.matchLevel && hit._highlightResult.content?.matchLevel !== 'none';

	if (
		indexName === CATEGORY_TITLES.COMPONENTS &&
		hasMatchInContent &&
		!hasMatchInTitle &&
		!hasMatchInDescription
	) {
		path = `${hit.path}/${hit.contentTab}`;
	}

	let showDescription = hasMatchInDescription;
	if (!hasMatchInDescription && !hasMatchInContent) {
		showDescription = true;
	}

	return (
		<LinkItem to={path} onClick={onClick}>
			<Inline space="space.100" alignBlock="center" spread="space-between">
				<Inline space="space.100">
					<Box xcss={dotStyles} style={{ backgroundColor: iconColorMapping[indexName] }} />
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
					<Stack space="space.100" xcss={xcss({ width: '100%' })}>
						<Text weight="bold">
							<Highlight hit={hit} attribute="title" />
						</Text>
						{showDescription && (
							<Text as="p" color="color.text.subtle">
								<Highlight hit={hit} attribute="description" />
							</Text>
						)}
						{hasMatchInContent && (
							<Text as="p" color="color.text.subtle">
								<Highlight hit={hit} attribute="content" />
							</Text>
						)}
					</Stack>
				</Inline>
				<ChevronRightIcon primaryColor={token('color.icon.subtle', N500)} label="arrow_right" />
			</Inline>
		</LinkItem>
	);
};

export default ResultItem;
