import IconMigration from "../../../../../../packages/design-system/icon/examples/constellation/icon-migration.tsx";
import IconMigrationDeprecated from "../../../../../../packages/design-system/icon/examples/constellation/icon-migration-deprecated.tsx";
import IconMigrationColor from "../../../../../../packages/design-system/icon/examples/constellation/icon-migration-color.tsx";
import IconMigrationMargin from "../../../../../../packages/design-system/icon/examples/constellation/icon-migration-margin.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-migration.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_deprecated_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-migration-deprecated.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_color_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-migration-color.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_margin_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-migration-margin.tsx";
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import * as React from 'react';
export default {
  IconMigration,
  IconMigrationDeprecated,
  IconMigrationColor,
  IconMigrationMargin,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_deprecated_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_color_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_margin_tsx,
  SectionMessage,
  SectionMessageAction,
  React
};