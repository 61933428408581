import { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { type StatusPanelProps } from '@af/design-system-docs-ui';

// @ts-expect-error -- untyped JS file
import { WORKAROUND_PAGE_ID } from '../../../constellation.config';
import { type SubPageConfig } from '../../../scripts/create-package-pages/types';
import type { NavigationItem } from '@af/design-system-docs-ui';
import { CATEGORY_TITLES, TOP_NAV_ITEMS } from '../../utils/constants';
import { filterReadyForPreview } from '../../utils/filter-ready-for-preview';

import getGuidelinePages from './get-guideline-pages';
import getPackagePages from './get-package-pages';

export type PackageInfo = {
	title: string;
	id: string;
	slug: string;
	status: StatusPanelProps | null;
	pages?: PackageInfo[];
	subPages?: SubPageConfig[];
};

export type Categories = { name: string; packages: PackageInfo[] }[];

export type ComponentPageInfo = {
	path: string;
	pageContext: {
		hasTabs: boolean;
		hasMdx?: boolean;
		hasCodeDocs?: boolean;
		hasProps?: boolean;
		mdxPath: string;
		name: string;
		slug: string;
		sortKey: string;
		title: string;
		status?: StatusPanelProps;
	};
};

/**
 * __Use side navigation__
 *
 * Statically queries all side navigation pages from Gatsby and
 * returns an organized hierarchy of pages.
 */
const useSideNavigation = (): NavigationItem[] => {
	const data: {
		allContentfulGuideline: {
			nodes: Queries.ContentfulGuideline[];
		};
		allWorkspaceInfo: {
			categories: Categories;
		};
		allSitePage: {
			componentPages: ComponentPageInfo[];
		};
	} = useStaticQuery(graphql`
		query {
			allContentfulGuideline(
				sort: { title: ASC }
				filter: {
					category: { glob: "?(Get started|Tokens|Brand|Foundations|Content|Patterns|Resources)" }
					slug: { ne: null }
				}
			) {
				nodes {
					contentful_id
					__typename
					slug
					title
					contentfulparent {
						slug
					}
					status
					readyForPreview
					category
				}
			}
			allWorkspaceInfo(sort: { title: ASC }) {
				categories: group(field: { category: SELECT }) {
					name: fieldValue
					packages: nodes {
						slug
						id
						title
						status
						pages
						subPages
					}
				}
			}
			allSitePage(filter: { path: { regex: "/^/components/[^$]/" } }) {
				componentPages: nodes {
					path
					pageContext
				}
			}
		}
	`);

	const navigationItems = useMemo(() => {
		const nodes = data.allContentfulGuideline.nodes.filter(
			/**
			 * This filter is necessary to avoid build errors from occurring when a Contentful content-type is no longer
			 * referenced anywhere in the source data. When this happens `gatsby-source-contentful` attempt will build a
			 * schema for GraphQL, which will fail if it is not able to find any data that corresponds to a content type queried here:
			 * `services/design-system-docs/src/templates/guideline-page.tsx`
			 *
			 * For context please see:
			 * - `https://www.gatsbyjs.com/plugins/gatsby-source-contentful/#restrictions-and-limitations`
			 * - `https://github.com/gatsbyjs/gatsby/issues/31385`
			 *
			 * This can be safely removed once this PR is merged and we have upgraded `gatsby-source-contentful`:
			 * https://github.com/gatsbyjs/gatsby/pull/30855
			 */
			({ contentful_id }: Queries.ContentfulGuideline) => contentful_id !== WORKAROUND_PAGE_ID,
		);

		const nodesForPreview = filterReadyForPreview(nodes);

		return TOP_NAV_ITEMS.map(({ title, slug }) => {
			// Filter nodes by current category
			const nodes = nodesForPreview.filter(
				({ category }: Queries.ContentfulGuideline) => category === title,
			);

			const navItem: NavigationItem = {
				item: {
					title,
					to: `/${slug}`,
				},
				subitems:
					title === CATEGORY_TITLES.COMPONENTS
						? getPackagePages({
								categories: data.allWorkspaceInfo.categories,
								componentPages: data.allSitePage.componentPages,
							})
						: getGuidelinePages({ guidelines: nodes, slug }),
			};

			return navItem;
		});
	}, [data]);

	return navigationItems;
};

export default useSideNavigation;
