import IconDefault from "../../../../../../packages/design-system/icon/examples/constellation/icon-default.tsx";
import IconMigration from "../../../../../../packages/design-system/icon/examples/constellation/icon-migration.tsx";
import IconSmall from "../../../../../../packages/design-system/icon/examples/constellation/icon-small.tsx";
import IconMedium from "../../../../../../packages/design-system/icon/examples/constellation/icon-medium.tsx";
import IconLarge from "../../../../../../packages/design-system/icon/examples/constellation/icon-large.tsx";
import IconPrimaryColor from "../../../../../../packages/design-system/icon/examples/constellation/icon-primary-color.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_default_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-default.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-migration.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_small_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-small.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_medium_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-medium.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_large_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-large.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_primary_color_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-primary-color.tsx";
import * as React from 'react';
export default {
  IconDefault,
  IconMigration,
  IconSmall,
  IconMedium,
  IconLarge,
  IconPrimaryColor,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_default_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_migration_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_small_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_medium_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_large_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_primary_color_tsx,
  React
};