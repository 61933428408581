/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, Global, jsx } from '@emotion/react';

import { NAVIGATION_BREAKPOINTS } from '@af/design-system-docs-ui';
import cssReset from '@atlaskit/css-reset';

/**
 * CSS Reset + general global styling hacks for all of Design System Docs
 */
const GlobalStyles = () => (
	<Global
		// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-imported-style-values, @repo/internal/react/no-css-string-literals -- Ignored via go/DSP-18766
		styles={css`
			${
				// eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values
				cssReset
			}
			* {
				box-sizing: inherit;
			}
			body,
			html {
				box-sizing: border-box;
			}

			img {
				max-width: 100%;
			}

			// CSS Reset sets marginTop of elements to 0 using first-child, which doesn't work with SSR. This override avoid margins jumping after SSR
			:first-child:is(p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table),
			style:first-child + :is(p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table) {
				margin-top: 0;
			}

			#___gatsby {
				isolation: isolate; // Hack to start new stacking context for content to prevent layering issues with portals
			}

			#___gatsby,
			#gatsby-focus-wrapper {
				height: 100%; // Allows the layout grid to be full height
			}

			@font-face {
				font-family: 'Charlie Display';
				src: url('/assets/CharlieDisplay-Regular.woff2') format('woff2');
				font-weight: 400;
				font-style: normal;
				font-display: swap;
			}

			@font-face {
				font-family: 'Charlie Display';
				src: url('/assets/CharlieDisplay-Bold.woff2') format('woff2');
				font-weight: 700;
				font-style: normal;
				font-display: swap;
			}

			${
				// eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values
				NAVIGATION_BREAKPOINTS.MOBILE
			} {
				:root {
					/**
           * TODO: Remove use of !important.
           * Page layout applies this as an inline style
           * to html for draggable resize areas...
           */
					--topNavigationHeight: 3rem !important;
				}
			}
		`}
	/>
);

export default GlobalStyles;
