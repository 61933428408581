/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * To change the format of this file, modify `createIconDocsNew` in icon-build-process/src/create-icon-docs.tsx.
 *
 * @codegen <<SignedSource::7ee5d608c596d6e618679140e4d13235>>
 * @codegenCommand yarn build:icon-glyphs
 */
interface metadata {
	/**
	 * Default component name for the icon
	 */
	componentName: string;

	/**
	 * Package path to the icon component
	 */
	package: string;

	/**
	 * The category of the icon:
	 * - 'single-purpose' icons should only be used for the purposes listed in the 'usage' field
	 * - 'multi-purpose' icons are designed for various use cases and don't have the same usage restrictions
	 * - 'utility' icons are used for utility purposes
	 */
	categorization: 'single-purpose' | 'multi-purpose' | 'utility';

	/**
	 * The type of the icon - either a 16px 'core' icon, or a 12px 'utility' icon
	 */
	type: 'core' | 'utility';

	/**
	 * Usage guidelines for the icon. For single-purpose icons,
	 */
	usage?: string;

	/**
	 * Additional keywords used to assist in search/lookup of an icon
	 */
	keywords: string[];

	/**
	 * The name of the team owning the icon
	 */
	team: string;

	/**
	 * The status of the icon
	 */
	status?: 'draft' | 'ready-to-publish' | 'published' | 'modified' | 'deprecated';

	/**
	 * Contact slack channel for the team owning the icon
	 */
	slackChannel?: string;

	/**
	 * A list of keys for old icons that have been replaced by this icon
	 */
	oldName?: string[];

	/**
	 * A replacement icon if this icon has been deprecated
	 */
	replacement?: {
		name: string;
		type: 'core' | 'utility';
		location: '@atlaskit/icon' | '@atlaskit/icon-lab' | '@atlassian/icon-private';
	};
}

const metadata: Record<string, metadata> = {
	add: {
		keywords: ['add', 'plus', 'create', 'new', 'icon', 'core', 'create', 'plus', 'jira status'],
		componentName: 'AddIcon',
		package: '@atlaskit/icon/core/add',
		oldName: ['add-circle', 'add', 'editor/add', 'emoji/custom'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for creating and adding an object.',
		team: 'Design System Team',
	},
	'ai-agent': {
		keywords: ['ai-agent', 'aiagent', 'icon', 'core', 'Rovo', 'AI', 'chat agent', 'ai'],
		componentName: 'AiAgentIcon',
		package: '@atlaskit/icon/core/ai-agent',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for Rovo Agent.',
		team: 'Design System Team',
	},
	'ai-chat': {
		keywords: ['ai-chat', 'aichat', 'icon', 'core', 'Rovo', 'AI', 'chat agent', 'ai'],
		componentName: 'AiChatIcon',
		package: '@atlaskit/icon/core/ai-chat',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for Rovo Chat.',
		team: 'Design System Team',
	},
	alert: {
		keywords: ['alert', 'icon', 'core', 'alert', 'event', 'operations'],
		componentName: 'AlertIcon',
		package: '@atlaskit/icon/core/alert',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for alerts in JSM and Compass.',
		team: 'Design System Team',
	},
	'align-center': {
		keywords: ['align-center', 'aligncenter', 'icon', 'core', 'alignment', 'text', 'content'],
		componentName: 'AlignCenterIcon',
		package: '@atlaskit/icon/core/align-center',
		oldName: ['editor/align-center'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: align text center, align center.',
		team: 'Design System Team',
	},
	'align-left': {
		keywords: [
			'align-left',
			'alignleft',
			'icon',
			'core',
			'alignment',
			'text',
			'content',
			'summary',
		],
		componentName: 'AlignLeftIcon',
		package: '@atlaskit/icon/core/align-left',
		oldName: ['editor/align-left', 'overview'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: align text left, align content left, summary.',
		team: 'Design System Team',
	},
	'align-right': {
		keywords: ['align-right', 'alignright', 'icon', 'core', 'alignment', 'text', 'content'],
		componentName: 'AlignRightIcon',
		package: '@atlaskit/icon/core/align-right',
		oldName: ['editor/align-right'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: align text right, align content right.',
		team: 'Design System Team',
	},
	'angle-brackets': {
		keywords: [
			'angle-brackets',
			'anglebrackets',
			'icon',
			'core',
			'code',
			'<>',
			'</>',
			'syntax',
			'jira status',
		],
		componentName: 'AngleBracketsIcon',
		package: '@atlaskit/icon/core/angle-brackets',
		oldName: ['bitbucket/repos', 'bitbucket/source', 'code', 'editor/code', 'media-services/code'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: code or source code in Bitbucket and Jira.',
		team: 'Design System Team',
	},
	api: {
		keywords: ['api', 'icon', 'core', 'application programming interface', 'api', 'operations'],
		componentName: 'ApiIcon',
		package: '@atlaskit/icon/core/api',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing Application Programming Interfaces (APIs).',
		team: 'Enterprise Operations Center',
	},
	app: {
		keywords: [
			'app',
			'icon',
			'core',
			'add-on',
			'add on',
			'plugin',
			'external app',
			'third-party app',
		],
		componentName: 'AppIcon',
		package: '@atlaskit/icon/core/app',
		oldName: ['addon'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for marketplace apps and integrations across products.',
		team: 'Design System Team',
	},
	'app-switcher': {
		keywords: [
			'app-switcher',
			'appswitcher',
			'icon',
			'core',
			'application switcher',
			'change product',
			'switch product',
			'product switcher',
		],
		componentName: 'AppSwitcherIcon',
		package: '@atlaskit/icon/core/app-switcher',
		oldName: ['app-switcher', 'switcher'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for app switcher in global product navigation.',
		team: 'Design System Team',
	},
	'app-switcher-legacy': {
		keywords: [
			'app-switcher-legacy',
			'appswitcherlegacy',
			'icon',
			'core',
			'application switcher',
			'change product',
			'switch product',
			'product switcher',
		],
		componentName: 'AppSwitcherLegacyIcon',
		package: '@atlaskit/icon/core/app-switcher-legacy',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for the legacy app switcher in global product navigation.',
		team: 'Design System Team',
	},
	apps: {
		keywords: ['apps', 'icon', 'core', 'third-party', 'applications'],
		componentName: 'AppsIcon',
		package: '@atlaskit/icon/core/apps',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for adding or viewing apps.',
		team: 'Design System Team',
	},
	'archive-box': {
		keywords: ['archive-box', 'archivebox', 'icon', 'core', 'file box'],
		componentName: 'ArchiveBoxIcon',
		package: '@atlaskit/icon/core/archive-box',
		oldName: ['archive'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: archiving pages, storage.',
		team: 'Design System Team',
	},
	'arrow-down': {
		keywords: ['arrow-down', 'arrowdown', 'icon', 'core', 'down', 'bottom', 'sorting'],
		componentName: 'ArrowDownIcon',
		package: '@atlaskit/icon/core/arrow-down',
		oldName: ['arrow-down-circle', 'arrow-down'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Know uses: sorting table headers or Bitbucket code difference.',
		team: 'Design System Team',
	},
	'arrow-down-left': {
		keywords: [
			'arrow-down-left',
			'arrowdownleft',
			'icon',
			'core',
			'diagonal arrow',
			'down',
			'left',
			'south west',
		],
		componentName: 'ArrowDownLeftIcon',
		package: '@atlaskit/icon/core/arrow-down-left',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Adding content from Rovo Chat into Editor.',
		team: 'Design System Team',
	},
	'arrow-down-right': {
		keywords: [
			'arrow-down-right',
			'arrowdownright',
			'icon',
			'core',
			'diagonal arrow',
			'down',
			'right',
			'south east',
		],
		componentName: 'ArrowDownRightIcon',
		package: '@atlaskit/icon/core/arrow-down-right',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: TBC',
		team: 'Design System Team',
	},
	'arrow-left': {
		keywords: ['arrow-left', 'arrowleft', 'back', 'previous', 'icon', 'core', 'back', 'previous'],
		componentName: 'ArrowLeftIcon',
		package: '@atlaskit/icon/core/arrow-left',
		oldName: ['arrow-left-circle', 'arrow-left'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: back to previous screen, previous slide.',
		team: 'Design System Team',
	},
	'arrow-right': {
		keywords: [
			'arrow-right',
			'arrowright',
			'forward',
			'next',
			'icon',
			'core',
			'forward',
			'next',
			'link',
		],
		componentName: 'ArrowRightIcon',
		package: '@atlaskit/icon/core/arrow-right',
		oldName: ['arrow-right-circle', 'arrow-right'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: link to nested menu item, a linked menu item, next slide.',
		team: 'Design System Team',
	},
	'arrow-up': {
		keywords: ['arrow-up', 'arrowup', 'icon', 'core', 'improvement', 'jira status'],
		componentName: 'ArrowUpIcon',
		package: '@atlaskit/icon/core/arrow-up',
		oldName: ['arrow-up-circle', 'arrow-up'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: back to top.',
		team: 'Design System Team',
	},
	'arrow-up-left': {
		keywords: [
			'arrow-up-left',
			'arrowupleft',
			'icon',
			'core',
			'diagonal arrow',
			'up',
			'right',
			'north east',
		],
		componentName: 'ArrowUpLeftIcon',
		package: '@atlaskit/icon/core/arrow-up-left',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: TBC',
		team: 'Design System Team',
	},
	'arrow-up-right': {
		keywords: ['arrow-up-right', 'arrowupright', 'icon', 'core', 'open', 'diagonal arrow'],
		componentName: 'ArrowUpRightIcon',
		package: '@atlaskit/icon/core/arrow-up-right',
		oldName: ['editor/open', 'open'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
	},
	assets: {
		keywords: ['assets', 'icon', 'core', 'assets', 'CMDB', 'configuration management database'],
		componentName: 'AssetsIcon',
		package: '@atlaskit/icon/core/assets',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for JSM Assets.',
		team: 'Assets',
	},
	'atlassian-intelligence': {
		keywords: ['atlassian-intelligence', 'atlassianintelligence', 'icon', 'core', 'AI'],
		componentName: 'AtlassianIntelligenceIcon',
		package: '@atlaskit/icon/core/atlassian-intelligence',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for Atlassian Intelligence products or experiences.',
		team: 'Design System Team',
	},
	attachment: {
		keywords: ['attachment', 'paperclip', 'icon', 'core', 'paperclip', 'attach'],
		componentName: 'AttachmentIcon',
		package: '@atlaskit/icon/core/attachment',
		oldName: ['attachment', 'editor/attachment'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for attaching files to issues or other objects.',
		team: 'Design System Team',
	},
	audio: {
		keywords: ['audio', 'music', 'note', 'sound', 'icon', 'core', 'music', 'musical note'],
		componentName: 'AudioIcon',
		package: '@atlaskit/icon/core/audio',
		oldName: ['audio-circle', 'audio', 'media-services/audio'],
		type: 'core',
		categorization: 'single-purpose',
		usage: '📦 @atlaskit/icon/core/audio',
		team: 'Design System Team',
	},
	automation: {
		keywords: ['automation', 'icon', 'core', 'lightningbolt', 'automation rule'],
		componentName: 'AutomationIcon',
		package: '@atlaskit/icon/core/automation',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved to represent an Automation.',
		team: 'Design System Team',
	},
	backlog: {
		keywords: ['backlog', 'icon', 'core', 'rows'],
		componentName: 'BacklogIcon',
		package: '@atlaskit/icon/core/backlog',
		oldName: ['backlog'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for backlogs in Jira.',
		team: 'Design System Team',
	},
	basketball: {
		keywords: ['basketball', 'icon', 'core', 'ball', 'sports', 'basketball'],
		componentName: 'BasketballIcon',
		package: '@atlaskit/icon/core/basketball',
		oldName: ['emoji/activity'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Sport emoji category.',
		team: 'Design System Team',
	},
	board: {
		keywords: ['board', 'icon', 'core', 'columns', 'active sprint'],
		componentName: 'BoardIcon',
		package: '@atlaskit/icon/core/board',
		oldName: ['board'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for boards in Jira.',
		team: 'Design System Team',
	},
	boards: {
		keywords: ['boards', 'icon', 'core'],
		componentName: 'BoardsIcon',
		package: '@atlaskit/icon/core/boards',
		oldName: ['switcher'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved as the icon to represent multiple boards.',
		team: 'Design System Team',
	},
	'book-with-bookmark': {
		keywords: [
			'book-with-bookmark',
			'bookwithbookmark',
			'icon',
			'core',
			'knowledge base',
			'article',
		],
		componentName: 'BookWithBookmarkIcon',
		package: '@atlaskit/icon/core/book-with-bookmark',
		oldName: ['book'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: knowledge bases, articles, and other representations of books or info.',
		team: 'Design System Team',
	},
	border: {
		keywords: [
			'border',
			'icon',
			'core',
			'border',
			'image border',
			'content border',
			'editor',
			'confluence',
		],
		componentName: 'BorderIcon',
		package: '@atlaskit/icon/core/border',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for toggling the visibility of a border on an object.',
		team: 'Editor',
	},
	'border-weight-medium': {
		keywords: [
			'border-weight-medium',
			'borderweightmedium',
			'icon',
			'core',
			'border',
			'weight',
			'stroke',
			'medium',
			'thickness',
			'confluence',
			'editor',
		],
		componentName: 'BorderWeightMediumIcon',
		package: '@atlaskit/icon/core/border-weight-medium',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing medium border stroke widths.',
		team: 'Editor',
	},
	'border-weight-thick': {
		keywords: [
			'border-weight-thick',
			'borderweightthick',
			'icon',
			'core',
			'border',
			'weight',
			'thickness',
			'stroke',
			'thick',
			'confluence',
			'editor',
		],
		componentName: 'BorderWeightThickIcon',
		package: '@atlaskit/icon/core/border-weight-thick',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing thick border stroke widths.',
		team: 'Editor',
	},
	'border-weight-thin': {
		keywords: [
			'border-weight-thin',
			'borderweightthin',
			'icon',
			'core',
			'border',
			'weight',
			'thickness',
			'stroke',
			'confluence',
			'editor',
		],
		componentName: 'BorderWeightThinIcon',
		package: '@atlaskit/icon/core/border-weight-thin',
		oldName: ['media-services/line'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing thin border stroke widths.',
		team: 'Editor',
	},
	branch: {
		keywords: [
			'branch',
			'icon',
			'core',
			'git branch',
			'bitbucket branch',
			'branches',
			'jira status',
		],
		componentName: 'BranchIcon',
		package: '@atlaskit/icon/core/branch',
		oldName: ['bitbucket/branches'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for branches in Bitbucket and Jira.',
		team: 'Design System Team',
	},
	briefcase: {
		keywords: ['briefcase', 'icon', 'core', 'suitcase', 'toolbox', 'operations', 'business'],
		componentName: 'BriefcaseIcon',
		package: '@atlaskit/icon/core/briefcase',
		oldName: ['suitcase'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: Job title in Atlas, Operations in JSM.',
		team: 'Design System Team',
	},
	bug: {
		keywords: ['bug', 'icon', 'core', 'bug report', 'test'],
		componentName: 'BugIcon',
		package: '@atlaskit/icon/core/bug',
		oldName: ['jira/test-session'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: Request types in JSM, bugs in Jira.',
		team: 'Design System Team',
	},
	calendar: {
		keywords: ['calendar', 'date', 'icon', 'core', 'date', 'month', 'day', 'year', 'jira status'],
		componentName: 'CalendarIcon',
		package: '@atlaskit/icon/core/calendar',
		oldName: ['calendar-filled', 'calendar', 'editor/date'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: date metadata, date input field, calendar view, jira status.',
		team: 'Design System Team',
	},
	'calendar-plus': {
		keywords: [
			'calendar-plus',
			'calendarplus',
			'icon',
			'core',
			'calendar',
			'add',
			'plus',
			'schedule',
		],
		componentName: 'CalendarPlusIcon',
		package: '@atlaskit/icon/core/calendar-plus',
		oldName: ['schedule-filled', 'schedule'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Auto-scheduling in Jira Plans. Scheduled dates.',
		team: 'Design System Team',
	},
	camera: {
		keywords: ['camera', 'photo', 'icon', 'core'],
		componentName: 'CameraIcon',
		package: '@atlaskit/icon/core/camera',
		oldName: ['camera-filled', 'camera', 'editor/photo'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: upload photo in Trello, photos.',
		team: 'Design System Team',
	},
	capture: {
		keywords: ['capture', 'icon', 'core'],
		componentName: 'CaptureIcon',
		package: '@atlaskit/icon/core/capture',
		oldName: ['jira/capture'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: '📦 @atlaskit/icon/core/capture',
		team: 'Design System Team',
	},
	card: {
		keywords: ['card', 'icon', 'core', 'card'],
		componentName: 'CardIcon',
		package: '@atlaskit/icon/core/card',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Representing cards in Trello. Toggling card detail in Jira.',
		team: 'Design System Team',
	},
	cash: {
		keywords: ['cash', 'icon', 'core', 'currency', 'money', 'cash', 'dollar', 'bill'],
		componentName: 'CashIcon',
		package: '@atlaskit/icon/core/cash',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'Sales' Jira issue type.",
		team: 'Design System Team',
	},
	changes: {
		keywords: ['changes', 'icon', 'core', 'jira status', 'horizontal arrows'],
		componentName: 'ChangesIcon',
		package: '@atlaskit/icon/core/changes',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for changes in Jira.',
		team: 'Design System Team',
	},
	'chart-bar': {
		keywords: ['chart-bar', 'chartbar', 'icon', 'core', 'graph', 'bar', 'analytics', 'report'],
		componentName: 'ChartBarIcon',
		package: '@atlaskit/icon/core/chart-bar',
		oldName: ['graph-bar', 'media-services/presentation'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: Reports in JSM, Space Analytics in Confluence, and other graph charts.',
		team: 'Design System Team',
	},
	'chart-matrix': {
		keywords: ['chart-matrix', 'chartmatrix', 'icon', 'core', 'dot chart', 'graph', 'matrix', ''],
		componentName: 'ChartMatrixIcon',
		package: '@atlaskit/icon/core/chart-matrix',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: Matrix view in in JPD, and other matrix charts.',
		team: 'Design System Team',
	},
	'chart-pie': {
		keywords: ['chart-pie', 'chartpie', 'icon', 'core', 'segment', 'chart', 'graph', 'pie'],
		componentName: 'ChartPieIcon',
		package: '@atlaskit/icon/core/chart-pie',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: pie charts.',
		team: 'Design System Team',
	},
	'chart-trend': {
		keywords: [
			'chart-trend',
			'charttrend',
			'icon',
			'core',
			'reports',
			'graph',
			'impact effort',
			'',
		],
		componentName: 'ChartTrendIcon',
		package: '@atlaskit/icon/core/chart-trend',
		oldName: ['graph-line'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: charts, reports in Jira, and sprint insights.',
		team: 'Design System Team',
	},
	'chat-widget': {
		keywords: [
			'chat-widget',
			'chatwidget',
			'icon',
			'core',
			'chat',
			'widget',
			'virtual service agent',
			'vsa',
		],
		componentName: 'ChatWidgetIcon',
		package: '@atlaskit/icon/core/chat-widget',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing a chat widget triggered by a floating action button.',
		team: 'JSM',
	},
	'check-circle': {
		keywords: [
			'check-circle',
			'checkcircle',
			'tick',
			'icon',
			'core',
			'tick',
			'yes',
			'completed',
			'filled',
		],
		componentName: 'CheckCircleIcon',
		package: '@atlaskit/icon/core/check-circle',
		oldName: ['check-circle', 'check-circle-outline', 'emoji/productivity'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: completed items, productivity emoji category. Completed task issue status in JSM Calendar view.',
		team: 'Design System Team',
	},
	'check-mark': {
		keywords: ['check-mark', 'checkmark', 'icon', 'core', 'tick'],
		componentName: 'CheckMarkIcon',
		package: '@atlaskit/icon/core/check-mark',
		oldName: ['check', 'editor/done'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: table cells, checkboxes.',
		team: 'Design System Team',
	},
	'checkbox-checked': {
		keywords: [
			'checkbox-checked',
			'checkboxchecked',
			'icon',
			'core',
			'filled',
			'checked',
			'select all',
		],
		componentName: 'CheckboxCheckedIcon',
		package: '@atlaskit/icon/core/checkbox-checked',
		oldName: ['checkbox', 'editor/task'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Reserved for interactive checkbox experiences. Consider using the checkbox component.',
		team: 'Design System Team',
	},
	'checkbox-indeterminate': {
		keywords: [
			'checkbox-indeterminate',
			'checkboxindeterminate',
			'icon',
			'core',
			'filled',
			'mixed',
		],
		componentName: 'CheckboxIndeterminateIcon',
		package: '@atlaskit/icon/core/checkbox-indeterminate',
		oldName: ['checkbox-indeterminate'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Reserved for interactive checkbox experiences. Consider using the checkbox component.',
		team: 'Design System Team',
	},
	'checkbox-unchecked': {
		keywords: ['checkbox-unchecked', 'checkboxunchecked', 'icon', 'core', 'unchecked'],
		componentName: 'CheckboxUncheckedIcon',
		package: '@atlaskit/icon/core/checkbox-unchecked',
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Reserved for interactive checkbox experiences. Consider using the checkbox component.',
		team: 'Design System Team',
	},
	'child-issues': {
		keywords: ['child-issues', 'childissues', 'icon', 'core'],
		componentName: 'ChildIssuesIcon',
		package: '@atlaskit/icon/core/child-issues',
		oldName: ['child-issues'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for child issues in Jira.',
		team: 'Design System Team',
	},
	clipboard: {
		keywords: ['clipboard', 'icon', 'core', 'clipboard', 'paste'],
		componentName: 'ClipboardIcon',
		package: '@atlaskit/icon/core/clipboard',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Pasting content from clipboard.',
		team: 'Design System Team',
	},
	clock: {
		keywords: ['clock', 'icon', 'core', 'time', 'recent', 'history'],
		componentName: 'ClockIcon',
		package: '@atlaskit/icon/core/clock',
		oldName: ['editor/recent', 'emoji/frequent', 'recent'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: recent, time input, sprint time remaining. Overdue task issue status in JSM Calendar view.',
		team: 'Design System Team',
	},
	close: {
		keywords: ['close', 'icon', 'core', 'cross', 'x'],
		componentName: 'CloseIcon',
		package: '@atlaskit/icon/core/close',
		oldName: ['cross', 'editor/close'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for closing an element.',
		team: 'Design System Team',
	},
	'cloud-arrow-up': {
		keywords: ['cloud-arrow-up', 'cloudarrowup', 'icon', 'core', 'deployments', 'up arrow'],
		componentName: 'CloudArrowUpIcon',
		package: '@atlaskit/icon/core/cloud-arrow-up',
		oldName: ['upload'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: deployments in Jira.',
		team: 'Design System Team',
	},
	'collapse-horizontal': {
		keywords: [
			'collapse-horizontal',
			'collapsehorizontal',
			'icon',
			'core',
			'collapse',
			'width',
			'horizontal arrows',
		],
		componentName: 'CollapseHorizontalIcon',
		package: '@atlaskit/icon/core/collapse-horizontal',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for contracting or reducing the width of an element to its smallest size.',
		team: 'Design System Team',
	},
	'collapse-vertical': {
		keywords: [
			'collapse-vertical',
			'collapsevertical',
			'icon',
			'core',
			'collapse',
			'height',
			'vertical arrows',
		],
		componentName: 'CollapseVerticalIcon',
		package: '@atlaskit/icon/core/collapse-vertical',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for contracting or reducing the height of an element to its smallest size.',
		team: 'Design System Team',
	},
	comment: {
		keywords: ['comment', 'chat', 'speech', 'icon', 'core', 'speech bubble'],
		componentName: 'CommentIcon',
		package: '@atlaskit/icon/core/comment',
		oldName: ['comment', 'questions'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for comments on objects.',
		team: 'Design System Team',
	},
	'comment-add': {
		keywords: ['comment-add', 'commentadd', 'icon', 'core', 'speech bubble', 'plus'],
		componentName: 'CommentAddIcon',
		package: '@atlaskit/icon/core/comment-add',
		oldName: ['media-services/add-comment'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for adding a comment to an object.',
		team: 'Design System Team',
	},
	commit: {
		keywords: ['commit', 'icon', 'core', 'git commit', 'bitbucket commit'],
		componentName: 'CommitIcon',
		package: '@atlaskit/icon/core/commit',
		oldName: ['bitbucket/commits'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for commits in Jira or Bitbucket.',
		team: 'Design System Team',
	},
	compass: {
		keywords: ['compass', 'icon', 'core', 'template'],
		componentName: 'CompassIcon',
		package: '@atlaskit/icon/core/compass',
		oldName: ['discover-filled', 'discover'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: templates.',
		team: 'Design System Team',
	},
	component: {
		keywords: ['component', 'block', 'lego', 'icon', 'core', 'lego', 'brick', 'block'],
		componentName: 'ComponentIcon',
		package: '@atlaskit/icon/core/component',
		oldName: ['component'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for components in Jira and Compass.',
		team: 'Design System Team',
	},
	'content-align-center': {
		keywords: [
			'content-align-center',
			'contentaligncenter',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'alignment',
			'centre',
		],
		componentName: 'ContentAlignCenterIcon',
		package: '@atlaskit/icon/core/content-align-center',
		oldName: ['editor/align-image-center'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for center aligning media and content.',
		team: 'Design System Team',
	},
	'content-align-left': {
		keywords: [
			'content-align-left',
			'contentalignleft',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'alignment',
			'left',
		],
		componentName: 'ContentAlignLeftIcon',
		package: '@atlaskit/icon/core/content-align-left',
		oldName: ['editor/align-image-left'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for left aligning media and content.',
		team: 'Design System Team',
	},
	'content-align-right': {
		keywords: [
			'content-align-right',
			'contentalignright',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'alignment',
			'right',
		],
		componentName: 'ContentAlignRightIcon',
		package: '@atlaskit/icon/core/content-align-right',
		oldName: ['editor/align-image-right'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for right aligning media and content.',
		team: 'Design System Team',
	},
	'content-width-narrow': {
		keywords: [
			'content-width-narrow',
			'contentwidthnarrow',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'width',
			'fixed',
			'narrow',
		],
		componentName: 'ContentWidthNarrowIcon',
		package: '@atlaskit/icon/core/content-width-narrow',
		oldName: ['editor/media-center'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for setting media and content to a narrow width.',
		team: 'Design System Team',
	},
	'content-width-wide': {
		keywords: [
			'content-width-wide',
			'contentwidthwide',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'width',
			'fixed',
			'wide',
		],
		componentName: 'ContentWidthWideIcon',
		package: '@atlaskit/icon/core/content-width-wide',
		oldName: ['editor/media-wide'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for setting media and content to a wide width.',
		team: 'Design System Team',
	},
	'content-wrap-left': {
		keywords: [
			'content-wrap-left',
			'contentwrapleft',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'alignment',
			'left',
			'inline',
			'wrap',
		],
		componentName: 'ContentWrapLeftIcon',
		package: '@atlaskit/icon/core/content-wrap-left',
		oldName: ['editor/media-wrap-left'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for left aligning media and content with wrapping enabled.',
		team: 'Design System Team',
	},
	'content-wrap-right': {
		keywords: [
			'content-wrap-right',
			'contentwrapright',
			'icon',
			'core',
			'content',
			'media',
			'image',
			'alignment',
			'right',
			'inline',
			'wrap',
		],
		componentName: 'ContentWrapRightIcon',
		package: '@atlaskit/icon/core/content-wrap-right',
		oldName: ['editor/media-wrap-right'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for right aligning media and content with wrapping enabled.',
		team: 'Design System Team',
	},
	copy: {
		keywords: ['copy', 'duplicate', 'icon', 'core', 'copy', 'object'],
		componentName: 'CopyIcon',
		package: '@atlaskit/icon/core/copy',
		oldName: ['copy'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for copying data such as text, code or other objects.',
		team: 'Design System Team',
	},
	'credit-card': {
		keywords: ['credit-card', 'creditcard', 'icon', 'core', 'payment', 'invoice'],
		componentName: 'CreditCardIcon',
		package: '@atlaskit/icon/core/credit-card',
		oldName: ['creditcard-filled', 'creditcard'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: billing in Admin, invoices in PPC, payments.',
		team: 'Design System Team',
	},
	'cross-circle': {
		keywords: [
			'cross-circle',
			'crosscircle',
			'close',
			'x',
			'cancel',
			'icon',
			'core',
			'x',
			'exit',
			'clear',
			'no',
			'filled',
		],
		componentName: 'CrossCircleIcon',
		package: '@atlaskit/icon/core/cross-circle',
		oldName: ['cross-circle', 'editor/error', 'select-clear'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: clear text field, error status.',
		team: 'Design System Team',
	},
	'curly-brackets': {
		keywords: [
			'curly-brackets',
			'curlybrackets',
			'icon',
			'core',
			'curly brackets',
			'braces',
			'smart value',
		],
		componentName: 'CurlyBracketsIcon',
		package: '@atlaskit/icon/core/curly-brackets',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Represents Smart values in Proforma.',
		team: 'Design System Team',
	},
	customize: {
		keywords: [
			'customize',
			'icon',
			'core',
			'customise',
			'configure',
			'modify',
			'preferences',
			'settings',
			'sliders',
		],
		componentName: 'CustomizeIcon',
		package: '@atlaskit/icon/core/customize',
		oldName: ['preferences'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: customize sidebar, customize view, settings.',
		team: 'Design System Team',
	},
	dashboard: {
		keywords: ['dashboard', 'window', 'grid', 'icon', 'core', 'activity', 'view'],
		componentName: 'DashboardIcon',
		package: '@atlaskit/icon/core/dashboard',
		oldName: ['activity', 'dashboard'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for dashboards in Jira.',
		team: 'Design System Team',
	},
	'data-flow': {
		keywords: ['data-flow', 'dataflow', 'icon', 'core', 'relationship', 'data', 'flow chart'],
		componentName: 'DataFlowIcon',
		package: '@atlaskit/icon/core/data-flow',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Database schema in Jira.',
		team: 'Design System Team',
	},
	'data-number': {
		keywords: [
			'data-number',
			'datanumber',
			'icon',
			'core',
			'numbers',
			'123',
			'proforma',
			'datatype',
		],
		componentName: 'DataNumberIcon',
		package: '@atlaskit/icon/core/data-number',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Representing number datatype fields in Proforma and databases.',
		team: 'Design System Team',
	},
	'data-string': {
		keywords: [
			'data-string',
			'datastring',
			'icon',
			'core',
			'string',
			'letters',
			'abc',
			'proforma',
			'datatype',
		],
		componentName: 'DataStringIcon',
		package: '@atlaskit/icon/core/data-string',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Representing string datatype fields in Proforma and databases.',
		team: 'Design System Team',
	},
	database: {
		keywords: ['database', 'icon', 'core', 'spreadsheet', 'table', 'data', 'cells'],
		componentName: 'DatabaseIcon',
		package: '@atlaskit/icon/core/database',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for databases in Confluence.',
		team: 'Design System Team',
	},
	decision: {
		keywords: ['decision', 'icon', 'core', 'fork', 'diagonal arrow'],
		componentName: 'DecisionIcon',
		package: '@atlaskit/icon/core/decision',
		oldName: ['decision', 'editor/decision'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for decisions.',
		team: 'Design System Team',
	},
	defect: {
		keywords: ['defect', 'icon', 'core', 'square', 'broken', 'defect', 'fragile', 'cracked'],
		componentName: 'DefectIcon',
		package: '@atlaskit/icon/core/defect',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for Defect Jira issue type.',
		team: 'Design System Team',
	},
	delete: {
		keywords: ['delete', 'icon', 'core', 'trash', 'bin', 'remove'],
		componentName: 'DeleteIcon',
		package: '@atlaskit/icon/core/delete',
		oldName: ['editor/remove', 'trash'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for removing or deleting an object.',
		team: 'Design System Team',
	},
	department: {
		keywords: [
			'department',
			'icon',
			'core',
			'organization',
			'organisation',
			'org chart',
			'hierarchy',
		],
		componentName: 'DepartmentIcon',
		package: '@atlaskit/icon/core/department',
		oldName: ['department'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for departments, reporting lines, or other tree chart representations.',
		team: 'Design System Team',
	},
	'device-mobile': {
		keywords: [
			'device-mobile',
			'devicemobile',
			'icon',
			'core',
			'iphone',
			'mobile phone',
			'cell phone',
		],
		componentName: 'DeviceMobileIcon',
		package: '@atlaskit/icon/core/device-mobile',
		oldName: ['mobile'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: call, contact us.',
		team: 'Design System Team',
	},
	devices: {
		keywords: ['devices', 'icon', 'core', 'devices', 'assets', 'laptop', 'phone', 'hardware'],
		componentName: 'DevicesIcon',
		package: '@atlaskit/icon/core/devices',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'Asset' Jira issue type.",
		team: 'Design System Team',
	},
	discovery: {
		keywords: ['discovery', 'icon', 'core', 'discovery', 'note', 'filled'],
		componentName: 'DiscoveryIcon',
		package: '@atlaskit/icon/core/discovery',
		oldName: ['editor/note'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for representing discovery. Used for Note info panel variant in Editor.',
		team: 'Design System Team',
	},
	download: {
		keywords: ['download', 'cloud', 'icon', 'core', 'down arrow', 'file download'],
		componentName: 'DownloadIcon',
		package: '@atlaskit/icon/core/download',
		oldName: ['download'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for file downloads.',
		team: 'Design System Team',
	},
	'drag-handle': {
		keywords: [
			'drag-handle',
			'draghandle',
			'icon',
			'core',
			'drag handler',
			'drag dots',
			'reorder',
			'move',
		],
		componentName: 'DragHandleIcon',
		package: '@atlaskit/icon/core/drag-handle',
		oldName: ['drag-handler'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for draggable elements.',
		team: 'Design System Team',
	},
	edit: {
		keywords: ['edit', 'pencil', 'write', 'icon', 'core', 'pencil', 'pencil on page'],
		componentName: 'EditIcon',
		package: '@atlaskit/icon/core/edit',
		oldName: ['edit-filled', 'edit', 'editor/edit'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for editing objects.',
		team: 'Design System Team',
	},
	'edit-bulk': {
		keywords: [
			'edit-bulk',
			'editbulk',
			'icon',
			'core',
			'edit',
			'pencil',
			'multiple',
			'bulk',
			'change',
		],
		componentName: 'EditBulkIcon',
		package: '@atlaskit/icon/core/edit-bulk',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for editing multiple objects in bulk.',
		team: 'Design System Team',
	},
	email: {
		keywords: ['email', 'icon', 'core', 'envelope', 'message'],
		componentName: 'EmailIcon',
		package: '@atlaskit/icon/core/email',
		oldName: ['email'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for when an email-related things.',
		team: 'Design System Team',
	},
	emoji: {
		keywords: ['emoji', 'emoticon', 'smiley', 'icon', 'core', 'smiley face', 'emoticon'],
		componentName: 'EmojiIcon',
		package: '@atlaskit/icon/core/emoji',
		oldName: ['editor/emoji', 'emoji/emoji', 'emoji', 'emoji/people'],
		type: 'core',
		categorization: 'single-purpose',
		usage: "Single purpose - Reserved for Editor as a category for Emoji's.",
		team: 'Design System Team',
	},
	'emoji-add': {
		keywords: ['emoji-add', 'emojiadd', 'icon', 'core', 'smiley face', 'emoticon', 'plus'],
		componentName: 'EmojiAddIcon',
		package: '@atlaskit/icon/core/emoji-add',
		oldName: ['emoji-add'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for adding an emoji reaction.',
		team: 'Design System Team',
	},
	'emoji-casual': {
		keywords: [
			'emoji-casual',
			'emojicasual',
			'icon',
			'core',
			'emoij',
			'casual',
			'sunglasses',
			'chill',
			'relaxed',
		],
		componentName: 'EmojiCasualIcon',
		package: '@atlaskit/icon/core/emoji-casual',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing a casual or relaxed tone or sentiment.',
		team: 'Design System Team',
	},
	'emoji-neutral': {
		keywords: ['emoji-neutral', 'emojineutral', 'icon', 'core', 'emoji', 'neutral', 'ambivalent'],
		componentName: 'EmojiNeutralIcon',
		package: '@atlaskit/icon/core/emoji-neutral',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing a neutral tone or sentiment.',
		team: 'Design System Team',
	},
	'emoji-remove': {
		keywords: ['emoji-remove', 'emojiremove', 'icon', 'core', 'emoji', 'remove', 'strikethrough'],
		componentName: 'EmojiRemoveIcon',
		package: '@atlaskit/icon/core/emoji-remove',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for removing emoji.',
		team: 'Design System Team',
	},
	epic: {
		keywords: ['epic', 'icon', 'core', 'lightning bolt', 'jira status', 'filled'],
		componentName: 'EpicIcon',
		package: '@atlaskit/icon/core/epic',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for epics in Jira.',
		team: 'Design System Team',
	},
	error: {
		keywords: ['error', 'warning', 'alert', 'icon', 'core', 'filled', 'status'],
		componentName: 'ErrorIcon',
		package: '@atlaskit/icon/core/error',
		oldName: ['error'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for when there is an error.',
		team: 'Design System Team',
	},
	'expand-horizontal': {
		keywords: [
			'expand-horizontal',
			'expandhorizontal',
			'icon',
			'core',
			'expand',
			'width',
			'horizontal arrows',
			'maximum width',
			'stretch',
			'fit',
		],
		componentName: 'ExpandHorizontalIcon',
		package: '@atlaskit/icon/core/expand-horizontal',
		oldName: ['editor/media-full-width'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for expanding an element to its maximum width.',
		team: 'Design System Team',
	},
	'expand-vertical': {
		keywords: [
			'expand-vertical',
			'expandvertical',
			'icon',
			'core',
			'expand',
			'height',
			'vertical arrows',
			'maximum height',
			'stretch',
			'fit',
		],
		componentName: 'ExpandVerticalIcon',
		package: '@atlaskit/icon/core/expand-vertical',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for expanding an element to its maximum height.',
		team: 'Design System Team',
	},
	'eye-open': {
		keywords: [
			'eye-open',
			'eyeopen',
			'icon',
			'core',
			'watch',
			'visible',
			'visbility',
			'permissions',
		],
		componentName: 'EyeOpenIcon',
		package: '@atlaskit/icon/core/eye-open',
		oldName: ['watch'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: watch page in Confluence, show password in text field, and following in Atlas.',
		team: 'Design System Team',
	},
	'eye-open-filled': {
		keywords: [
			'eye-open-filled',
			'eyeopenfilled',
			'icon',
			'core',
			'watching',
			'visible',
			'visbility',
			'permissions',
			'filled',
		],
		componentName: 'EyeOpenFilledIcon',
		package: '@atlaskit/icon/core/eye-open-filled',
		oldName: ['hipchat/audio-only', 'watch-filled'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: watched pages in Confluence.',
		team: 'Design System Team',
	},
	'eye-open-strikethrough': {
		keywords: [
			'eye-open-strikethrough',
			'eyeopenstrikethrough',
			'icon',
			'core',
			'unwatch',
			'invisible',
			'visibility',
			'permissions',
		],
		componentName: 'EyeOpenStrikethroughIcon',
		package: '@atlaskit/icon/core/eye-open-strikethrough',
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Intended uses: unwatch page in Confluence, hide password in text field, and unfollow in Atlas.',
		team: 'Design System Team',
	},
	feed: {
		keywords: ['feed', 'icon', 'core', 'feed', 'updates', 'release notes', "what's new"],
		componentName: 'FeedIcon',
		package: '@atlaskit/icon/core/feed',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for update feeds in Atlassian Home.',
		team: 'Design System Team',
	},
	feedback: {
		keywords: [
			'feedback',
			'announce',
			'speaker',
			'megaphone',
			'icon',
			'core',
			'diagonal arrow',
			'chat bubble',
			'survey',
			'critique',
		],
		componentName: 'FeedbackIcon',
		package: '@atlaskit/icon/core/feedback',
		oldName: ['editor/feedback', 'feedback'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Known uses: customer feedback.',
		team: 'Design System Team',
	},
	field: {
		keywords: ['field', 'icon', 'core', 'field', 'form', 'input', 'label'],
		componentName: 'FieldIcon',
		package: '@atlaskit/icon/core/field',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Field suggestions provided by Atlassian Intelligence.',
		team: 'Design System Team',
	},
	'field-alert': {
		keywords: ['field-alert', 'fieldalert', 'icon', 'core', 'field', 'alert', 'warning', 'change'],
		componentName: 'FieldAlertIcon',
		package: '@atlaskit/icon/core/field-alert',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Changed field values in Automation.',
		team: 'Design System Team',
	},
	'field-checkbox-group': {
		keywords: [
			'field-checkbox-group',
			'fieldcheckboxgroup',
			'icon',
			'core',
			'form',
			'field',
			'input type',
			'checkbox',
			'multi-select',
			'options',
		],
		componentName: 'FieldCheckboxGroupIcon',
		package: '@atlaskit/icon/core/field-checkbox-group',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Checkbox group field type in Proforma.',
		team: 'Design System Team',
	},
	'field-dropdown': {
		keywords: [
			'field-dropdown',
			'fielddropdown',
			'icon',
			'core',
			'form',
			'field',
			'select',
			'dropdown',
		],
		componentName: 'FieldDropdownIcon',
		package: '@atlaskit/icon/core/field-dropdown',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Dropdown field type in Proforma.',
		team: 'Design System Team',
	},
	'field-radio-group': {
		keywords: [
			'field-radio-group',
			'fieldradiogroup',
			'icon',
			'core',
			'form',
			'field',
			'input type',
			'radio',
			'single-select',
			'options',
		],
		componentName: 'FieldRadioGroupIcon',
		package: '@atlaskit/icon/core/field-radio-group',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Radio group field type in Proforma.',
		team: 'Design System Team',
	},
	file: {
		keywords: [
			'file',
			'document',
			'paper',
			'page',
			'sheet',
			'icon',
			'core',
			'document',
			'file',
			'paper',
		],
		componentName: 'FileIcon',
		package: '@atlaskit/icon/core/file',
		oldName: ['document-filled', 'document', 'editor/file', 'file'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			"Multi purpose - Known uses: document, file. Do not use to represent a page — use the dedicated 'Page' icon instead.",
		team: 'Design System Team',
	},
	files: {
		keywords: ['files', 'icon', 'core', 'documents', 'files', 'papers'],
		componentName: 'FilesIcon',
		package: '@atlaskit/icon/core/files',
		oldName: ['documents'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			"Multi purpose - Known uses: documents, files. Do not use to represent pages — use the dedicated 'Pages' icon instead.",
		team: 'Design System Team',
	},
	filter: {
		keywords: ['filter', 'icon', 'core', 'funnel', 'refine'],
		componentName: 'FilterIcon',
		package: '@atlaskit/icon/core/filter',
		oldName: ['filter', 'media-services/filter'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for filterting data or objects.',
		team: 'Design System Team',
	},
	flag: {
		keywords: ['flag', 'icon', 'core', 'important', 'emoji category'],
		componentName: 'FlagIcon',
		package: '@atlaskit/icon/core/flag',
		oldName: ['emoji/flags'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: flags in Editor, feature flags.',
		team: 'Design System Team',
	},
	'flag-filled': {
		keywords: ['flag-filled', 'flagfilled', 'icon', 'core', 'flag', 'important', 'filled'],
		componentName: 'FlagFilledIcon',
		package: '@atlaskit/icon/core/flag-filled',
		oldName: ['flag-filled'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: active feature flags.',
		team: 'Design System Team',
	},
	flask: {
		keywords: ['flask', 'icon', 'core', 'labs', 'test', 'erlenmeyer flask', 'beaker'],
		componentName: 'FlaskIcon',
		package: '@atlaskit/icon/core/flask',
		oldName: ['jira/labs'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: labs in Jira.',
		team: 'Design System Team',
	},
	'folder-closed': {
		keywords: ['folder-closed', 'folderclosed', 'icon', 'core', 'directory'],
		componentName: 'FolderClosedIcon',
		package: '@atlaskit/icon/core/folder-closed',
		oldName: ['folder'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for folders in Confluence.',
		team: 'Design System Team',
	},
	'folder-open': {
		keywords: ['folder-open', 'folderopen', 'icon', 'core', 'directory'],
		componentName: 'FolderOpenIcon',
		package: '@atlaskit/icon/core/folder-open',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for open folders in Confluence.',
		team: 'Design System Team',
	},
	form: {
		keywords: ['form', 'icon', 'core', 'form', 'fields'],
		componentName: 'FormIcon',
		package: '@atlaskit/icon/core/form',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Represents forms across Atlassian products, including Proforma.',
		team: 'Design System Team',
	},
	'fullscreen-enter': {
		keywords: ['fullscreen-enter', 'fullscreenenter', 'icon', 'core', 'full screen'],
		componentName: 'FullscreenEnterIcon',
		package: '@atlaskit/icon/core/fullscreen-enter',
		oldName: ['vid-full-screen-on'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for full screen videos or objects.',
		team: 'Design System Team',
	},
	'fullscreen-exit': {
		keywords: [
			'fullscreen-exit',
			'fullscreenexit',
			'icon',
			'core',
			'un-full screen',
			'un-fullscreen',
		],
		componentName: 'FullscreenExitIcon',
		package: '@atlaskit/icon/core/fullscreen-exit',
		oldName: ['media-services/full-screen'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for return screen videos or objects.',
		team: 'Design System Team',
	},
	glasses: {
		keywords: [
			'glasses',
			'icon',
			'core',
			'glasses',
			'knowledge',
			'learning',
			'spectacles',
			'education',
		],
		componentName: 'GlassesIcon',
		package: '@atlaskit/icon/core/glasses',
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Known uses: Changing tone of written content to Educational with Atlassian Intelligence.',
		team: 'Design System Team',
	},
	globe: {
		keywords: ['globe', 'icon', 'core', 'world'],
		componentName: 'GlobeIcon',
		package: '@atlaskit/icon/core/globe',
		oldName: ['world', 'world-small'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: public link in Confluence share dialog, global rules in Automation.',
		team: 'Design System Team',
	},
	goal: {
		keywords: ['goal', 'icon', 'core', 'target'],
		componentName: 'GoalIcon',
		package: '@atlaskit/icon/core/goal',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for goals in Atlas.',
		team: 'Design System Team',
	},
	grid: {
		keywords: ['grid', 'icon', 'core', 'view all content', 'tile view', 'layout', 'grid', 'tiles'],
		componentName: 'GridIcon',
		package: '@atlaskit/icon/core/grid',
		oldName: ['editor/table', 'media-services/grid', 'table'],
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: spaces in Confluence, and grid view, all content in Confluence.',
		team: 'Design System Team',
	},
	'grow-diagonal': {
		keywords: [
			'grow-diagonal',
			'growdiagonal',
			'icon',
			'core',
			'grow',
			'width and height',
			'diagonal arrows',
		],
		componentName: 'GrowDiagonalIcon',
		package: '@atlaskit/icon/core/grow-diagonal',
		oldName: ['media-services/actual-size'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for increasing the size of an element when height and width are changed concurrently.',
		team: 'Design System Team',
	},
	'grow-horizontal': {
		keywords: [
			'grow-horizontal',
			'growhorizontal',
			'icon',
			'core',
			'grow',
			'width',
			'horizontal arrows',
		],
		componentName: 'GrowHorizontalIcon',
		package: '@atlaskit/icon/core/grow-horizontal',
		oldName: ['editor/expand'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for increasing the width of an element.',
		team: 'Design System Team',
	},
	'grow-vertical': {
		keywords: [
			'grow-vertical',
			'growvertical',
			'icon',
			'core',
			'grow',
			'height',
			'vertical arrows',
		],
		componentName: 'GrowVerticalIcon',
		package: '@atlaskit/icon/core/grow-vertical',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for increasing the height of an element.',
		team: 'Design System Team',
	},
	hashtag: {
		keywords: ['hashtag', 'icon', 'core', 'tag', 'topic', 'pound'],
		componentName: 'HashtagIcon',
		package: '@atlaskit/icon/core/hashtag',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: topics in Atlas, tags.',
		team: 'Design System Team',
	},
	headphones: {
		keywords: ['headphones', 'icon', 'core', 'audio', 'music'],
		componentName: 'HeadphonesIcon',
		package: '@atlaskit/icon/core/headphones',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: music, audio.',
		team: 'Design System Team',
	},
	heart: {
		keywords: ['heart', 'icon', 'core', 'like', 'love', 'emoji category'],
		componentName: 'HeartIcon',
		package: '@atlaskit/icon/core/heart',
		oldName: ['emoji/symbols'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: emoji symbols in Editor.',
		team: 'Design System Team',
	},
	highlight: {
		keywords: ['highlight', 'icon', 'core', 'highlight', 'highlighter', 'stabilo', 'pen'],
		componentName: 'HighlightIcon',
		package: '@atlaskit/icon/core/highlight',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for highlight text tool in Confluence Editor.',
		team: 'Design System Team',
	},
	home: {
		keywords: ['home', 'icon', 'core', 'house', 'building'],
		componentName: 'HomeIcon',
		package: '@atlaskit/icon/core/home',
		oldName: ['home-circle', 'home'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for home in navigation. When a user clicks on this, they should return to the homepage.',
		team: 'Design System Team',
	},
	image: {
		keywords: ['image', 'picture', 'photo', 'icon', 'core', 'picture', 'asset'],
		componentName: 'ImageIcon',
		package: '@atlaskit/icon/core/image',
		oldName: [
			'editor/image',
			'image',
			'media-services/image',
			'media-services/scale-large',
			'media-services/scale-small',
		],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: images, image upload.',
		team: 'Design System Team',
	},
	'image-fullscreen': {
		keywords: [
			'image-fullscreen',
			'imagefullscreen',
			'icon',
			'core',
			'image',
			'fullscreen',
			'enlarge',
		],
		componentName: 'ImageFullscreenIcon',
		package: '@atlaskit/icon/core/image-fullscreen',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for viewing an image in a fullscreen view or modal.',
		team: 'Editor',
	},
	'image-inline': {
		keywords: ['image-inline', 'imageinline', 'icon', 'core', 'image', 'layout', 'inline'],
		componentName: 'ImageInlineIcon',
		package: '@atlaskit/icon/core/image-inline',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing inline images in Editor.',
		team: 'Editor',
	},
	'image-scaled': {
		keywords: ['image-scaled', 'imagescaled', 'icon', 'core', 'image', 'layout', 'scaled'],
		componentName: 'ImageScaledIcon',
		package: '@atlaskit/icon/core/image-scaled',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing scaled images in layouts akin to display block.',
		team: 'Editor',
	},
	inbox: {
		keywords: ['inbox', 'icon', 'core', 'document tray', 'work', 'letter', 'post'],
		componentName: 'InboxIcon',
		package: '@atlaskit/icon/core/inbox',
		oldName: ['tray'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known uses: 'Your work' in Confluence, inbox, mail.",
		team: 'Design System Team',
	},
	incident: {
		keywords: ['incident', 'icon', 'core', 'witches hat', 'traffic cone', 'jira status'],
		componentName: 'IncidentIcon',
		package: '@atlaskit/icon/core/incident',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for incidents in Jira.',
		team: 'Design System Team',
	},
	information: {
		keywords: ['information', 'icon', 'core', 'info', 'filled', 'tip'],
		componentName: 'InformationIcon',
		package: '@atlaskit/icon/core/information',
		oldName: ['editor/info', 'editor/panel', 'info'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Known uses: information state in section messages or inline messages.',
		team: 'Design System Team',
	},
	issue: {
		keywords: [
			'issue',
			'tick',
			'check',
			'confirm',
			'task',
			'icon',
			'core',
			'single issue',
			'jira status',
			'check mark',
			'tick',
		],
		componentName: 'IssueIcon',
		package: '@atlaskit/icon/core/issue',
		oldName: ['issue'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for issues in Jira.',
		team: 'Design System Team',
	},
	issues: {
		keywords: [
			'issues',
			'tick',
			'check',
			'confirm',
			'task',
			'icon',
			'core',
			'multiple issues',
			'check mark',
			'tick',
		],
		componentName: 'IssuesIcon',
		package: '@atlaskit/icon/core/issues',
		oldName: ['issues'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for multiple issues in Jira.',
		team: 'Design System Team',
	},
	'key-result': {
		keywords: [
			'key-result',
			'keyresult',
			'icon',
			'core',
			'target',
			'bullseye',
			'key result',
			'arrow',
			'bow',
			'archery',
			'OKR',
		],
		componentName: 'KeyResultIcon',
		package: '@atlaskit/icon/core/key-result',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing key results.',
		team: 'Atlas',
	},
	'layout-one-column': {
		keywords: ['layout-one-column', 'layoutonecolumn', 'icon', 'core', 'layout', 'column', '1 col'],
		componentName: 'LayoutOneColumnIcon',
		package: '@atlaskit/icon/core/layout-one-column',
		oldName: ['editor/layout-single'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for single column layout option in Confluence Editor',
		team: 'Design System Team',
	},
	'layout-three-columns': {
		keywords: [
			'layout-three-columns',
			'layoutthreecolumns',
			'icon',
			'core',
			'layout',
			'columns',
			'3 col',
			'3 cols',
		],
		componentName: 'LayoutThreeColumnsIcon',
		package: '@atlaskit/icon/core/layout-three-columns',
		oldName: ['editor/layout-three-equal'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for 3 column layout option in Confluence Editor.',
		team: 'Design System Team',
	},
	'layout-three-columns-sidebars': {
		keywords: [
			'layout-three-columns-sidebars',
			'layoutthreecolumnssidebars',
			'icon',
			'core',
			'layout',
			'columns',
			'3 col',
			'3 cols',
			'sidebars',
			'asides',
		],
		componentName: 'LayoutThreeColumnsSidebarsIcon',
		package: '@atlaskit/icon/core/layout-three-columns-sidebars',
		oldName: ['editor/layout-three-with-sidebars'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for 3 column layout with left and right sidebars option in Confluence Editor.',
		team: 'Design System Team',
	},
	'layout-two-columns': {
		keywords: [
			'layout-two-columns',
			'layouttwocolumns',
			'icon',
			'core',
			'layout',
			'columns',
			'2 col',
			'2 cols',
		],
		componentName: 'LayoutTwoColumnsIcon',
		package: '@atlaskit/icon/core/layout-two-columns',
		oldName: ['editor/layout-two-equal'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for 2 column layout option in Confluence Editor.',
		team: 'Design System Team',
	},
	'layout-two-columns-sidebar-left': {
		keywords: [
			'layout-two-columns-sidebar-left',
			'layouttwocolumnssidebarleft',
			'icon',
			'core',
			'layout',
			'columns',
			'2 col',
			'2 cols',
			'sidebar',
			'aside',
		],
		componentName: 'LayoutTwoColumnsSidebarLeftIcon',
		package: '@atlaskit/icon/core/layout-two-columns-sidebar-left',
		oldName: ['editor/layout-two-left-sidebar'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for 2 column layout with left sidebar option in Confluence Editor.',
		team: 'Design System Team',
	},
	'layout-two-columns-sidebar-right': {
		keywords: [
			'layout-two-columns-sidebar-right',
			'layouttwocolumnssidebarright',
			'icon',
			'core',
			'layout',
			'columns',
			'2 col',
			'2 cols',
			'sidebar',
			'aside',
		],
		componentName: 'LayoutTwoColumnsSidebarRightIcon',
		package: '@atlaskit/icon/core/layout-two-columns-sidebar-right',
		oldName: ['editor/layout-two-right-sidebar'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for 2 column layout with right sidebar option in Confluence Editor.',
		team: 'Design System Team',
	},
	library: {
		keywords: ['library', 'icon', 'core', 'library', 'drawer', 'drawers', 'filing cabinet'],
		componentName: 'LibraryIcon',
		package: '@atlaskit/icon/core/library',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for library view in Atlassian Home.',
		team: 'Design System Team',
	},
	lightbulb: {
		keywords: [
			'lightbulb',
			'idea',
			'hint',
			'icon',
			'core',
			'idea',
			'initiative',
			'tip',
			'learnings',
		],
		componentName: 'LightbulbIcon',
		package: '@atlaskit/icon/core/lightbulb',
		oldName: ['editor/hint', 'emoji/objects', 'lightbulb-filled', 'lightbulb'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: learnings in Atlas.',
		team: 'Design System Team',
	},
	link: {
		keywords: ['link', 'icon', 'core', 'url', 'hyperlink', 'website', 'www', 'http', ''],
		componentName: 'LinkIcon',
		package: '@atlaskit/icon/core/link',
		oldName: ['editor/link', 'link-filled', 'link'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for links, urls, or copy link.',
		team: 'Design System Team',
	},
	'link-broken': {
		keywords: [
			'link-broken',
			'linkbroken',
			'icon',
			'core',
			'unlink',
			'remove link',
			'break link',
			'url',
			'hyperlink',
			'website',
			'www',
			'https',
		],
		componentName: 'LinkBrokenIcon',
		package: '@atlaskit/icon/core/link-broken',
		oldName: ['editor/unlink', 'unlink'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for removing a link.',
		team: 'Design System Team',
	},
	'link-external': {
		keywords: [
			'link-external',
			'linkexternal',
			'icon',
			'core',
			'new tab',
			'new window',
			'open in',
			'url',
			'hyperlink',
			'www',
			'http',
			'https',
			'website',
			'external',
			'shortcut',
			'diagonal arrow',
			'offsite',
		],
		componentName: 'LinkExternalIcon',
		package: '@atlaskit/icon/core/link-external',
		oldName: ['shortcut'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for links that open up a new tab.',
		team: 'Design System Team',
	},
	'list-bulleted': {
		keywords: ['list-bulleted', 'listbulleted', 'icon', 'core', 'bullets', 'unordered list'],
		componentName: 'ListBulletedIcon',
		package: '@atlaskit/icon/core/list-bulleted',
		oldName: ['bullet-list', 'editor/bullet-list'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: bulleted lists, view all.',
		team: 'Design System Team',
	},
	'list-checklist': {
		keywords: [
			'list-checklist',
			'listchecklist',
			'icon',
			'core',
			'list',
			'check mark',
			'to-do',
			'requirements',
		],
		componentName: 'ListChecklistIcon',
		package: '@atlaskit/icon/core/list-checklist',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'Requirement' Jira issue type.",
		team: 'Design System Team',
	},
	'list-numbered': {
		keywords: ['list-numbered', 'listnumbered', 'icon', 'core', 'list', 'numbers'],
		componentName: 'ListNumberedIcon',
		package: '@atlaskit/icon/core/list-numbered',
		oldName: ['editor/number-list'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Numbered list in Confluence Editor.',
		team: 'Design System Team',
	},
	'lobby-bell': {
		keywords: ['lobby-bell', 'lobbybell', 'icon', 'core', 'ding', 'risks'],
		componentName: 'LobbyBellIcon',
		package: '@atlaskit/icon/core/lobby-bell',
		oldName: ['hipchat/lobby'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: risks in Atlas.',
		team: 'Design System Team',
	},
	location: {
		keywords: ['location', 'pin', 'gps', 'map', 'icon', 'core', 'map', 'pin', 'address'],
		componentName: 'LocationIcon',
		package: '@atlaskit/icon/core/location',
		oldName: ['location'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: location in Atlas.',
		team: 'Design System Team',
	},
	'lock-locked': {
		keywords: [
			'lock-locked',
			'locklocked',
			'icon',
			'core',
			'permissions',
			'no access',
			'restricted',
			'security',
			'secure',
			'forbidden',
			'authentication',
		],
		componentName: 'LockLockedIcon',
		package: '@atlaskit/icon/core/lock-locked',
		oldName: ['lock-circle', 'lock-filled', 'lock', 'unlock-circle'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: secure password in textfields, locked page in Confluence.',
		team: 'Design System Team',
	},
	'lock-unlocked': {
		keywords: [
			'lock-unlocked',
			'lockunlocked',
			'icon',
			'core',
			'open permissions',
			'unrestricted access',
			'security',
			'insecure',
			'authentication',
		],
		componentName: 'LockUnlockedIcon',
		package: '@atlaskit/icon/core/lock-unlocked',
		oldName: ['unlock-filled', 'unlock'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: unlocked page in Confluence.',
		team: 'Design System Team',
	},
	'log-in': {
		keywords: ['log-in', 'login', 'icon', 'core', 'sign in', 'enter', 'account'],
		componentName: 'LogInIcon',
		package: '@atlaskit/icon/core/log-in',
		oldName: ['sign-in'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for log in.',
		team: 'Design System Team',
	},
	'log-out': {
		keywords: ['log-out', 'logout', 'icon', 'core', 'sign out', 'exit', 'account'],
		componentName: 'LogOutIcon',
		package: '@atlaskit/icon/core/log-out',
		oldName: ['sign-out'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for log out.',
		team: 'Design System Team',
	},
	'magic-wand': {
		keywords: ['magic-wand', 'magicwand', 'icon', 'core', 'magic', 'wand', 'suggestion'],
		componentName: 'MagicWandIcon',
		package: '@atlaskit/icon/core/magic-wand',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
	},
	markdown: {
		keywords: ['markdown', 'icon', 'core', 'markdown', 'md', 'markup'],
		componentName: 'MarkdownIcon',
		package: '@atlaskit/icon/core/markdown',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing the Markdown markup language.',
		team: 'Design System Team',
	},
	marketplace: {
		keywords: [
			'marketplace',
			'store',
			'shop',
			'icon',
			'core',
			'app store',
			'storefront',
			'stand',
			'third-party developer',
		],
		componentName: 'MarketplaceIcon',
		package: '@atlaskit/icon/core/marketplace',
		oldName: ['marketplace'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for Atlassian Marketplace.',
		team: 'Design System Team',
	},
	maximize: {
		keywords: ['maximize', 'icon', 'core', 'diagonal', 'resize', 'enlarge'],
		componentName: 'MaximizeIcon',
		package: '@atlaskit/icon/core/maximize',
		oldName: ['editor/image-resize', 'image-resize', 'media-services/actual-size'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for resizing screens, panels, modals, or media to its maximum size.',
		team: 'Design System Team',
	},
	megaphone: {
		keywords: ['megaphone', 'icon', 'core', 'announcement', 'bullhorn', 'feedback', 'news'],
		componentName: 'MegaphoneIcon',
		package: '@atlaskit/icon/core/megaphone',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: feedback in products.',
		team: 'Design System Team',
	},
	mention: {
		keywords: [
			'mention',
			'user',
			'person',
			'@',
			'icon',
			'core',
			'at symbol',
			'@',
			'tag',
			'username',
		],
		componentName: 'MentionIcon',
		package: '@atlaskit/icon/core/mention',
		oldName: ['editor/mention'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for user mentions.',
		team: 'Design System Team',
	},
	menu: {
		keywords: [
			'menu',
			'hamburger',
			'navigation',
			'switcher',
			'app switcher',
			'icon',
			'core',
			'menu',
			'top navigation',
			'3 lines',
			'hamburger',
		],
		componentName: 'MenuIcon',
		package: '@atlaskit/icon/core/menu',
		oldName: ['menu-expand', 'menu'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for accessing the menu in global product navigation.',
		team: 'Design System Team',
	},
	'merge-failure': {
		keywords: [
			'merge-failure',
			'mergefailure',
			'icon',
			'core',
			'git merge',
			'bitbucket merge',
			'merge fail',
			'cross',
			'x',
		],
		componentName: 'MergeFailureIcon',
		package: '@atlaskit/icon/core/merge-failure',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for branches in Bitbucket and Jira that have failed to merge.',
		team: 'Design System Team',
	},
	'merge-success': {
		keywords: [
			'merge-success',
			'mergesuccess',
			'icon',
			'core',
			'git merge',
			'bitbucket merge',
			'merge success',
			'check mark',
		],
		componentName: 'MergeSuccessIcon',
		package: '@atlaskit/icon/core/merge-success',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for branches in Bitbucket and Jira that have successfully merged.',
		team: 'Design System Team',
	},
	microphone: {
		keywords: ['microphone', 'icon', 'core', 'mic', 'mic on', 'voice', 'speak'],
		componentName: 'MicrophoneIcon',
		package: '@atlaskit/icon/core/microphone',
		oldName: ['vid-audio-on'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: record sounds in Trello.',
		team: 'Design System Team',
	},
	minimize: {
		keywords: ['minimize', 'icon', 'core', 'minimize', 'dock'],
		componentName: 'MinimizeIcon',
		package: '@atlaskit/icon/core/minimize',
		oldName: ['media-services/fit-to-page', 'vid-full-screen-off'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for minimizing or docking modals to the bottom of the viewport.',
		team: 'Design System Team',
	},
	minus: {
		keywords: ['minus', 'icon', 'core', 'rule', 'horizontal line', 'divider', 'minus', 'subtract'],
		componentName: 'MinusIcon',
		package: '@atlaskit/icon/core/minus',
		oldName: ['editor/divider', 'editor/horizontal-rule'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: horizontal rule in Editor.',
		team: 'Design System Team',
	},
	'minus-square': {
		keywords: ['minus-square', 'minussquare', 'icon', 'core', 'square', 'minus', 'subtract'],
		componentName: 'MinusSquareIcon',
		package: '@atlaskit/icon/core/minus-square',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'Remove feature' Jira issue status.",
		team: 'Design System Team',
	},
	node: {
		keywords: ['node', 'icon', 'core', 'page', 'dot', 'page tree', 'navigation'],
		componentName: 'NodeIcon',
		package: '@atlaskit/icon/core/node',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for a non-expandable item in a page tree.',
		team: 'Design System Team',
	},
	note: {
		keywords: ['note', 'icon', 'core', 'note', 'post-it', 'sticky'],
		componentName: 'NoteIcon',
		package: '@atlaskit/icon/core/note',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for notes.',
		team: 'Design System Team',
	},
	notification: {
		keywords: ['notification', 'bell', 'alarm', 'icon', 'core', 'bell', 'alert'],
		componentName: 'NotificationIcon',
		package: '@atlaskit/icon/core/notification',
		oldName: ['notification-direct', 'notification'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for notifications within global product navigation and within product screens.',
		team: 'Design System Team',
	},
	'notification-muted': {
		keywords: [
			'notification-muted',
			'notificationmuted',
			'icon',
			'core',
			'bell',
			'alert',
			'notification',
			'mute',
		],
		componentName: 'NotificationMutedIcon',
		package: '@atlaskit/icon/core/notification-muted',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for muting or disabling notifications.',
		team: 'Design System Team',
	},
	objective: {
		keywords: ['objective', 'icon', 'core', 'target', 'bullseye', 'objective'],
		componentName: 'ObjectiveIcon',
		package: '@atlaskit/icon/core/objective',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for objectives.',
		team: 'Design System Team',
	},
	'office-building': {
		keywords: [
			'office-building',
			'officebuilding',
			'icon',
			'core',
			'organization',
			'organisation',
			'business',
		],
		componentName: 'OfficeBuildingIcon',
		package: '@atlaskit/icon/core/office-building',
		oldName: ['office-building-filled', 'office-building'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: office in Atlas, company.',
		team: 'Design System Team',
	},
	'on-call': {
		keywords: ['on-call', 'oncall', 'icon', 'core', 'phone', 'on-call', 'support'],
		componentName: 'OnCallIcon',
		package: '@atlaskit/icon/core/on-call',
		oldName: ['hipchat/dial-out'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for representing on-call across Atlassian products.',
		team: 'Design System Team',
	},
	operations: {
		keywords: [
			'operations',
			'icon',
			'core',
			'incident management',
			'alerting',
			'opsgenie',
			'it operations',
			'it ops',
			'radar',
		],
		componentName: 'OperationsIcon',
		package: '@atlaskit/icon/core/operations',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for IT Operations.',
		team: 'Design System Team',
	},
	page: {
		keywords: [
			'page',
			'file',
			'document',
			'icon',
			'core',
			'single page',
			'feed',
			'document',
			'jira status',
		],
		componentName: 'PageIcon',
		package: '@atlaskit/icon/core/page',
		oldName: [
			'document-filled',
			'document',
			'editor/note',
			'media-services/document',
			'page-filled',
			'page',
		],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for pages in Confluence.',
		team: 'Design System Team',
	},
	pages: {
		keywords: ['pages', 'icon', 'core', 'multiple pages', 'feeds', 'documents'],
		componentName: 'PagesIcon',
		package: '@atlaskit/icon/core/pages',
		oldName: ['documents', 'queues'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for multipe pages in Confluence.',
		team: 'Design System Team',
	},
	'paint-bucket': {
		keywords: [
			'paint-bucket',
			'paintbucket',
			'icon',
			'core',
			'paint',
			'bucket',
			'fill',
			'background',
			'customize',
		],
		componentName: 'PaintBucketIcon',
		package: '@atlaskit/icon/core/paint-bucket',
		oldName: ['editor/background-color'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Customize fill color.',
		team: 'Design System Team',
	},
	'paint-palette': {
		keywords: ['paint-palette', 'paintpalette', 'icon', 'core', 'background', 'customize'],
		componentName: 'PaintPaletteIcon',
		package: '@atlaskit/icon/core/paint-palette',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Customize.',
		team: 'Design System Team',
	},
	pen: {
		keywords: ['pen', 'icon', 'core', 'pen tool', 'nib', 'fountain pen', 'design'],
		componentName: 'PenIcon',
		package: '@atlaskit/icon/core/pen',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'Design task' Jira issue type.",
		team: 'Design System Team',
	},
	'people-group': {
		keywords: [
			'people-group',
			'peoplegroup',
			'person',
			'user',
			'group',
			'icon',
			'core',
			'users',
			'customers',
			'people',
		],
		componentName: 'PeopleGroupIcon',
		package: '@atlaskit/icon/core/people-group',
		oldName: ['people-group', 'people'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Known uses: representing a group or collection of people or users.',
		team: 'Design System Team',
	},
	person: {
		keywords: ['person', 'person', 'user', 'avatar', 'icon', 'core', 'user', 'customer'],
		componentName: 'PersonIcon',
		package: '@atlaskit/icon/core/person',
		oldName: ['person'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Known uses: representing a person or user, owner, contributor.',
		team: 'Design System Team',
	},
	'person-add': {
		keywords: ['person-add', 'personadd', 'icon', 'core', 'user', 'customer', 'plus'],
		componentName: 'PersonAddIcon',
		package: '@atlaskit/icon/core/person-add',
		oldName: ['invite-team'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for adding a user to an object.',
		team: 'Design System Team',
	},
	'person-added': {
		keywords: ['person-added', 'personadded', 'icon', 'core', 'user', 'customer', 'check', 'tick'],
		componentName: 'PersonAddedIcon',
		package: '@atlaskit/icon/core/person-added',
		oldName: ['app-access', 'person-with-tick'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for a user added to an object.',
		team: 'Design System Team',
	},
	'person-avatar': {
		keywords: ['person-avatar', 'personavatar', 'icon', 'core', 'user', 'customer'],
		componentName: 'PersonAvatarIcon',
		package: '@atlaskit/icon/core/person-avatar',
		oldName: ['person-circle', 'user-avatar-circle'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for user avatar.',
		team: 'Design System Team',
	},
	'person-offboard': {
		keywords: [
			'person-offboard',
			'personoffboard',
			'icon',
			'core',
			'user',
			'customer',
			'right arrow',
		],
		componentName: 'PersonOffboardIcon',
		package: '@atlaskit/icon/core/person-offboard',
		oldName: ['followers'],
		type: 'core',
		categorization: 'single-purpose',
		usage: '📦 @atlaskit/icon/core/person-offboard',
		team: 'Design System Team',
	},
	'person-remove': {
		keywords: [
			'person-remove',
			'personremove',
			'icon',
			'core',
			'person',
			'remove',
			'delete',
			'unfollow',
		],
		componentName: 'PersonRemoveIcon',
		package: '@atlaskit/icon/core/person-remove',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for removing and unfollowing people and teammates.',
		team: 'Design System Team',
	},
	'person-warning': {
		keywords: ['person-warning', 'personwarning', 'icon', 'core', 'person', 'warning', 'alert'],
		componentName: 'PersonWarningIcon',
		package: '@atlaskit/icon/core/person-warning',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for showing a warning related to a person or teammate.',
		team: 'Design System Team',
	},
	phone: {
		keywords: ['phone', 'icon', 'core', 'call', 'dial out'],
		componentName: 'PhoneIcon',
		package: '@atlaskit/icon/core/phone',
		oldName: ['hipchat/dial-out'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: call us, phone number input.',
		team: 'Design System Team',
	},
	pin: {
		keywords: ['pin', 'icon', 'core', 'push pin', 'thumbtack', 'tack'],
		componentName: 'PinIcon',
		package: '@atlaskit/icon/core/pin',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: pinning Jira issue fields.',
		team: 'Design System Team',
	},
	'pin-filled': {
		keywords: [
			'pin-filled',
			'pinfilled',
			'icon',
			'core',
			'push pin',
			'thumbtack',
			'tack',
			'filled',
		],
		componentName: 'PinFilledIcon',
		package: '@atlaskit/icon/core/pin-filled',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: pinned Jira issue fields.',
		team: 'Design System Team',
	},
	'plus-square': {
		keywords: ['plus-square', 'plussquare', 'icon', 'core', 'square', 'plus', 'add'],
		componentName: 'PlusSquareIcon',
		package: '@atlaskit/icon/core/plus-square',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'New feature' Jira issue status.",
		team: 'Design System Team',
	},
	'power-plug': {
		keywords: ['power-plug', 'powerplug', 'icon', 'core', 'plug-in', 'add-on', 'socket'],
		componentName: 'PowerPlugIcon',
		package: '@atlaskit/icon/core/power-plug',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: plugins, add ons.',
		team: 'Design System Team',
	},
	premium: {
		keywords: ['premium', 'icon', 'core', 'AI', 'sparkles', 'stars', 'new', 'feature'],
		componentName: 'PremiumIcon',
		package: '@atlaskit/icon/core/premium',
		oldName: ['premium'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for premium features.',
		team: 'Design System Team',
	},
	'presenter-mode': {
		keywords: [
			'presenter-mode',
			'presentermode',
			'icon',
			'core',
			'pointer',
			'cursor',
			'presentation',
			'present',
		],
		componentName: 'PresenterModeIcon',
		package: '@atlaskit/icon/core/presenter-mode',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for presenter mode in Confluence.',
		team: 'Design System Team',
	},
	printer: {
		keywords: ['printer', 'icon', 'core', 'print'],
		componentName: 'PrinterIcon',
		package: '@atlaskit/icon/core/printer',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: print page, print-friendly view.',
		team: 'Design System Team',
	},
	'priority-blocker': {
		keywords: [
			'priority-blocker',
			'priorityblocker',
			'icon',
			'core',
			'blocked',
			'showstopper',
			'jira status',
		],
		componentName: 'PriorityBlockerIcon',
		package: '@atlaskit/icon/core/priority-blocker',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for blocker issue priority.',
		team: 'Design System Team',
	},
	'priority-critical': {
		keywords: [
			'priority-critical',
			'prioritycritical',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityCriticalIcon',
		package: '@atlaskit/icon/core/priority-critical',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for critial issue priority.',
		team: 'Design System Team',
	},
	'priority-high': {
		keywords: ['priority-high', 'priorityhigh', 'icon', 'core', 'issue', 'priority', 'jira status'],
		componentName: 'PriorityHighIcon',
		package: '@atlaskit/icon/core/priority-high',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for high issue priority.',
		team: 'Design System Team',
	},
	'priority-highest': {
		keywords: [
			'priority-highest',
			'priorityhighest',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityHighestIcon',
		package: '@atlaskit/icon/core/priority-highest',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for highest issue priority.',
		team: 'Design System Team',
	},
	'priority-low': {
		keywords: ['priority-low', 'prioritylow', 'icon', 'core', 'issue', 'priority', 'jira status'],
		componentName: 'PriorityLowIcon',
		package: '@atlaskit/icon/core/priority-low',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for low issue priority.',
		team: 'Design System Team',
	},
	'priority-lowest': {
		keywords: [
			'priority-lowest',
			'prioritylowest',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityLowestIcon',
		package: '@atlaskit/icon/core/priority-lowest',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for lowest issue priority.',
		team: 'Design System Team',
	},
	'priority-major': {
		keywords: [
			'priority-major',
			'prioritymajor',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityMajorIcon',
		package: '@atlaskit/icon/core/priority-major',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for major issue priority.',
		team: 'Design System Team',
	},
	'priority-medium': {
		keywords: [
			'priority-medium',
			'prioritymedium',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityMediumIcon',
		package: '@atlaskit/icon/core/priority-medium',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for medium issue priority.',
		team: 'Design System Team',
	},
	'priority-minor': {
		keywords: [
			'priority-minor',
			'priorityminor',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityMinorIcon',
		package: '@atlaskit/icon/core/priority-minor',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for minor issue priority.',
		team: 'Design System Team',
	},
	'priority-trivial': {
		keywords: [
			'priority-trivial',
			'prioritytrivial',
			'icon',
			'core',
			'issue',
			'priority',
			'jira status',
		],
		componentName: 'PriorityTrivialIcon',
		package: '@atlaskit/icon/core/priority-trivial',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for trivial issue priority.',
		team: 'Design System Team',
	},
	problem: {
		keywords: ['problem', 'icon', 'core', 'stop', 'issue', 'priority', 'jira status'],
		componentName: 'ProblemIcon',
		package: '@atlaskit/icon/core/problem',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for problem issue priority.',
		team: 'Design System Team',
	},
	project: {
		keywords: ['project', 'icon', 'core', 'rocket', 'rocketship', 'spaceship'],
		componentName: 'ProjectIcon',
		package: '@atlaskit/icon/core/project',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for projects in Jira.',
		team: 'Design System Team',
	},
	'project-status': {
		keywords: ['project-status', 'projectstatus', 'icon', 'core', 'status', 'traffic lights'],
		componentName: 'ProjectStatusIcon',
		package: '@atlaskit/icon/core/project-status',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing project status in Atlassian Home.',
		team: 'Atlassian Home',
	},
	'projection-screen': {
		keywords: [
			'projection-screen',
			'projectionscreen',
			'icon',
			'core',
			'present',
			'presentation',
			'projector screen',
			'keynote',
		],
		componentName: 'ProjectionScreenIcon',
		package: '@atlaskit/icon/core/projection-screen',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Intended usages: Presentation mode.',
		team: 'Design System Team',
	},
	'pull-request': {
		keywords: [
			'pull-request',
			'pullrequest',
			'icon',
			'core',
			'git pull request',
			'bitbucket pull request',
			'jira status',
		],
		componentName: 'PullRequestIcon',
		package: '@atlaskit/icon/core/pull-request',
		oldName: ['bitbucket/pullrequests'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for pull requests.',
		team: 'Design System Team',
	},
	pulse: {
		keywords: ['pulse', 'icon', 'core', 'pulse', 'wave', 'heartbeat', 'health'],
		componentName: 'PulseIcon',
		package: '@atlaskit/icon/core/pulse',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Single purpose - Known uses: work health in Atlassian Home.',
		team: 'Design System Team',
	},
	'question-circle': {
		keywords: [
			'question-circle',
			'questioncircle',
			'help',
			'icon',
			'core',
			'help',
			'answers',
			'faq',
			'jira status',
		],
		componentName: 'QuestionCircleIcon',
		package: '@atlaskit/icon/core/question-circle',
		oldName: ['editor/help', 'question-circle', 'question'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: help, tip.',
		team: 'Design System Team',
	},
	'quotation-mark': {
		keywords: [
			'quotation-mark',
			'quotationmark',
			'icon',
			'core',
			'quote',
			'testimonial',
			'blockquote',
			'jira status',
		],
		componentName: 'QuotationMarkIcon',
		package: '@atlaskit/icon/core/quotation-mark',
		oldName: ['editor/quote', 'quote'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: blockquote, comment, testimonial, blogs in Confluence.',
		team: 'Design System Team',
	},
	'radio-checked': {
		keywords: ['radio-checked', 'radiochecked', 'icon', 'core', 'radio', 'input type', 'selected'],
		componentName: 'RadioCheckedIcon',
		package: '@atlaskit/icon/core/radio-checked',
		oldName: ['radio'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for the selected state of radio controls.',
		team: 'Design System Team',
	},
	'radio-unchecked': {
		keywords: [
			'radio-unchecked',
			'radiounchecked',
			'icon',
			'core',
			'radio',
			'input type',
			'unselected',
		],
		componentName: 'RadioUncheckedIcon',
		package: '@atlaskit/icon/core/radio-unchecked',
		oldName: ['media-services/preselected'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for the unselected state of radio controls.',
		team: 'Design System Team',
	},
	redo: {
		keywords: ['redo', 'icon', 'core', 'editor', 'redo', 'backwards'],
		componentName: 'RedoIcon',
		package: '@atlaskit/icon/core/redo',
		oldName: ['editor/redo', 'redo'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for redo in Editor.',
		team: 'Design System Team',
	},
	refresh: {
		keywords: ['refresh', 'cycle', 'icon', 'core', 'diagonal', 'resize', 'arrows'],
		componentName: 'RefreshIcon',
		package: '@atlaskit/icon/core/refresh',
		oldName: ['refresh'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for expanding a object or panel.',
		team: 'Design System Team',
	},
	release: {
		keywords: ['release', 'icon', 'core', 'ship', 'boat'],
		componentName: 'ReleaseIcon',
		package: '@atlaskit/icon/core/release',
		oldName: ['ship'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for releases in Jira.',
		team: 'Design System Team',
	},
	retry: {
		keywords: ['retry', 'icon', 'core', 'try again', ''],
		componentName: 'RetryIcon',
		package: '@atlaskit/icon/core/retry',
		oldName: ['retry'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for retry.',
		team: 'Design System Team',
	},
	roadmap: {
		keywords: ['roadmap', 'icon', 'core'],
		componentName: 'RoadmapIcon',
		package: '@atlaskit/icon/core/roadmap',
		oldName: ['roadmap'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for roadmaps in Jira or Trello.',
		team: 'Design System Team',
	},
	scales: {
		keywords: ['scales', 'icon', 'core', 'scales', 'rule', 'law'],
		componentName: 'ScalesIcon',
		package: '@atlaskit/icon/core/scales',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Customer rating in Jira.',
		team: 'Design System Team',
	},
	scorecard: {
		keywords: ['scorecard', 'icon', 'core', 'tick', 'check', 'circle', 'unfinished'],
		componentName: 'ScorecardIcon',
		package: '@atlaskit/icon/core/scorecard',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for Scorecard in Analytics.',
		team: 'Design System Team',
	},
	screen: {
		keywords: [
			'screen',
			'desktop',
			'computer',
			'monitor',
			'icon',
			'core',
			'display',
			'monitor',
			'desktop',
		],
		componentName: 'ScreenIcon',
		package: '@atlaskit/icon/core/screen',
		oldName: ['screen'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: assets in Jira.',
		team: 'Design System Team',
	},
	'screen-plus': {
		keywords: [
			'screen-plus',
			'screenplus',
			'icon',
			'core',
			'screen',
			'display',
			'monitor',
			'plus',
			'add',
		],
		componentName: 'ScreenPlusIcon',
		package: '@atlaskit/icon/core/screen-plus',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: TBC',
		team: 'Design System Team',
	},
	search: {
		keywords: ['search', 'find', 'magnify', 'icon', 'core', 'magnifying glass'],
		componentName: 'SearchIcon',
		package: '@atlaskit/icon/core/search',
		oldName: ['editor/search', 'search'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for searching objects.',
		team: 'Design System Team',
	},
	send: {
		keywords: ['send', 'mail', 'icon', 'core', 'submit', 'paper airplane', 'paper aeroplane'],
		componentName: 'SendIcon',
		package: '@atlaskit/icon/core/send',
		oldName: ['send'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for sending messages in Rovo Chat.',
		team: 'Design System Team',
	},
	settings: {
		keywords: [
			'settings',
			'cog',
			'options',
			'configuration',
			'icon',
			'core',
			'system preferences',
			'gear',
			'cog',
		],
		componentName: 'SettingsIcon',
		package: '@atlaskit/icon/core/settings',
		oldName: ['editor/settings', 'settings'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for any object or user settings.',
		team: 'Design System Team',
	},
	shapes: {
		keywords: ['shapes', 'icon', 'core', 'objects', 'whiteboard', 'asset', 'graphic'],
		componentName: 'ShapesIcon',
		package: '@atlaskit/icon/core/shapes',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for shapes in Whiteboards.',
		team: 'Design System Team',
	},
	share: {
		keywords: ['share', 'icon', 'core', 'share', 'access'],
		componentName: 'ShareIcon',
		package: '@atlaskit/icon/core/share',
		oldName: ['share'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for sharing an object.',
		team: 'Design System Team',
	},
	shield: {
		keywords: [
			'shield',
			'icon',
			'core',
			'security',
			'secure',
			'safety',
			'defence',
			'protection',
			'guard',
		],
		componentName: 'ShieldIcon',
		package: '@atlaskit/icon/core/shield',
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Multi purpose - Known uses: classifications in Enterprise Trust, security in Jira (Spork)',
		team: 'Design System Team',
	},
	'shield-strikethrough': {
		keywords: [
			'shield-strikethrough',
			'shieldstrikethrough',
			'icon',
			'core',
			'️security',
			'secure',
			'safety',
			'defence',
			'protection',
			'guard',
			'strikethrough',
			'classification',
		],
		componentName: 'ShieldStrikethroughIcon',
		package: '@atlaskit/icon/core/shield-strikethrough',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: No classification in Enterprise Trust',
		team: 'Enterprise Trust',
	},
	shortcut: {
		keywords: ['shortcut', 'export', 'icon', 'core', 'addshortcut', 'square', 'plus'],
		componentName: 'ShortcutIcon',
		package: '@atlaskit/icon/core/shortcut',
		oldName: ['add-item'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for shortcuts in Jira.',
		team: 'Design System Team',
	},
	'show-more-horizontal': {
		keywords: [
			'show-more-horizontal',
			'showmorehorizontal',
			'icon',
			'core',
			'ellipses',
			'three dots',
			'meatball',
			'more actions',
		],
		componentName: 'ShowMoreHorizontalIcon',
		package: '@atlaskit/icon/core/show-more-horizontal',
		oldName: ['editor/more', 'more'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for more action menus.',
		team: 'Design System Team',
	},
	'show-more-vertical': {
		keywords: [
			'show-more-vertical',
			'showmorevertical',
			'icon',
			'core',
			'three dots',
			'kebab',
			'more actions',
		],
		componentName: 'ShowMoreVerticalIcon',
		package: '@atlaskit/icon/core/show-more-vertical',
		oldName: ['more-vertical'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for more action menus, traditionally on mobile.',
		team: 'Design System Team',
	},
	'shrink-diagonal': {
		keywords: ['shrink-diagonal', 'shrinkdiagonal', 'icon', 'core', 'resize', 'diagonal arrows'],
		componentName: 'ShrinkDiagonalIcon',
		package: '@atlaskit/icon/core/shrink-diagonal',
		oldName: ['media-services/fit-to-page', 'vid-full-screen-off'],
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Single purpose - Reserved for shrinking the height and width of modals, panels, media, or objects.',
		team: 'Design System Team',
	},
	'shrink-horizontal': {
		keywords: [
			'shrink-horizontal',
			'shrinkhorizontal',
			'icon',
			'core',
			'contract',
			'width',
			'horizontal arrows',
		],
		componentName: 'ShrinkHorizontalIcon',
		package: '@atlaskit/icon/core/shrink-horizontal',
		oldName: ['editor/collapse'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for contracting or reducing the width of an element.',
		team: 'Design System Team',
	},
	'shrink-vertical': {
		keywords: [
			'shrink-vertical',
			'shrinkvertical',
			'icon',
			'core',
			'contract',
			'height',
			'vertical arrows',
		],
		componentName: 'ShrinkVerticalIcon',
		package: '@atlaskit/icon/core/shrink-vertical',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for contracting or reducing the height of an element.',
		team: 'Design System Team',
	},
	'sidebar-collapse': {
		keywords: [
			'sidebar-collapse',
			'sidebarcollapse',
			'icon',
			'core',
			'navigation',
			'close sidebar',
		],
		componentName: 'SidebarCollapseIcon',
		package: '@atlaskit/icon/core/sidebar-collapse',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for collapsing the navigation sidebar.',
		team: 'Design System Team',
	},
	'sidebar-expand': {
		keywords: ['sidebar-expand', 'sidebarexpand', 'icon', 'core', 'navigation', 'open sidebar'],
		componentName: 'SidebarExpandIcon',
		package: '@atlaskit/icon/core/sidebar-expand',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for expanding the navigation sidebar.',
		team: 'Design System Team',
	},
	'sidebar-left': {
		keywords: [
			'sidebar-left',
			'sidebarleft',
			'icon',
			'core',
			'sidebar',
			'detail view',
			'left rail',
			'drawer',
		],
		componentName: 'SidebarLeftIcon',
		package: '@atlaskit/icon/core/sidebar-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for opening a sidebar to the left of the viewport edge.',
		team: 'Design System Team',
	},
	'sidebar-right': {
		keywords: [
			'sidebar-right',
			'sidebarright',
			'icon',
			'core',
			'sidebar',
			'detail view',
			'right rail',
			'drawer',
		],
		componentName: 'SidebarRightIcon',
		package: '@atlaskit/icon/core/sidebar-right',
		oldName: ['detail-view', 'room-menu'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for opening a sidebar to the right of the viewport edge.',
		team: 'Design System Team',
	},
	'smart-link': {
		keywords: ['smart-link', 'smartlink', 'icon', 'core', 'smart link'],
		componentName: 'SmartLinkIcon',
		package: '@atlaskit/icon/core/smart-link',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing Smart links.',
		team: 'Linking platform',
	},
	'smart-link-card': {
		keywords: [
			'smart-link-card',
			'smartlinkcard',
			'icon',
			'core',
			'smart link',
			'url',
			'card',
			'link preview',
		],
		componentName: 'SmartLinkCardIcon',
		package: '@atlaskit/icon/core/smart-link-card',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing Smart link preview cards.',
		team: 'Linking platform',
	},
	'smart-link-embed': {
		keywords: ['smart-link-embed', 'smartlinkembed', 'icon', 'core', 'smart link', 'url', 'embed'],
		componentName: 'SmartLinkEmbedIcon',
		package: '@atlaskit/icon/core/smart-link-embed',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing Smart link embeds.',
		team: 'Linking platform',
	},
	'smart-link-inline': {
		keywords: [
			'smart-link-inline',
			'smartlinkinline',
			'icon',
			'core',
			'smart link',
			'url',
			'inline',
		],
		componentName: 'SmartLinkInlineIcon',
		package: '@atlaskit/icon/core/smart-link-inline',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing inline Smart link lozenges.',
		team: 'Linking platform',
	},
	'smart-link-list': {
		keywords: [
			'smart-link-list',
			'smartlinklist',
			'icon',
			'core',
			'smart link',
			'url',
			'embed',
			'list',
			'table',
			'linked search results',
		],
		componentName: 'SmartLinkListIcon',
		package: '@atlaskit/icon/core/smart-link-list',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing Smart link embedded link lists.',
		team: 'Linking platform',
	},
	snippet: {
		keywords: ['snippet', 'icon', 'core', 'scissors', 'cut'],
		componentName: 'SnippetIcon',
		package: '@atlaskit/icon/core/snippet',
		oldName: ['bitbucket/snippets'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for snippets in Bitbucket.',
		team: 'Design System Team',
	},
	'sort-ascending': {
		keywords: ['sort-ascending', 'sortascending', 'icon', 'core', 'data', 'sort', 'up'],
		componentName: 'SortAscendingIcon',
		package: '@atlaskit/icon/core/sort-ascending',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for sorting data.',
		team: 'Design System Team',
	},
	'sort-descending': {
		keywords: ['sort-descending', 'sortdescending', 'icon', 'core', 'data', 'sort', 'down'],
		componentName: 'SortDescendingIcon',
		package: '@atlaskit/icon/core/sort-descending',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for sorting data.',
		team: 'Design System Team',
	},
	spreadsheet: {
		keywords: ['spreadsheet', 'icon', 'core', 'table', 'cells', 'data'],
		componentName: 'SpreadsheetIcon',
		package: '@atlaskit/icon/core/spreadsheet',
		oldName: ['media-services/spreadsheet'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for spreadsheets.',
		team: 'Design System Team',
	},
	sprint: {
		keywords: ['sprint', 'icon', 'core', 'loop', 'iterate'],
		componentName: 'SprintIcon',
		package: '@atlaskit/icon/core/sprint',
		oldName: ['sprint'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for sprints in Jira.',
		team: 'Design System Team',
	},
	'star-starred': {
		keywords: [
			'star-starred',
			'starstarred',
			'icon',
			'core',
			'favourite',
			'star',
			'starred',
			'filled',
		],
		componentName: 'StarStarredIcon',
		package: '@atlaskit/icon/core/star-starred',
		oldName: ['star-filled'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for starred or favourited objects.',
		team: 'Design System Team',
	},
	'star-unstarred': {
		keywords: ['star-unstarred', 'starunstarred', 'icon', 'core', 'favourite', 'star'],
		componentName: 'StarUnstarredIcon',
		package: '@atlaskit/icon/core/star-unstarred',
		oldName: ['star', 'star-large'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for starring or favoriting objects.',
		team: 'Design System Team',
	},
	'status-verified': {
		keywords: ['status-verified', 'statusverified', 'icon', 'core', 'verified badge', 'status'],
		componentName: 'StatusVerifiedIcon',
		package: '@atlaskit/icon/core/status-verified',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing verified content.',
		team: 'Design System Team',
	},
	stopwatch: {
		keywords: ['stopwatch', 'icon', 'core', 'timer'],
		componentName: 'StopwatchIcon',
		package: '@atlaskit/icon/core/stopwatch',
		oldName: ['stopwatch'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: timer in Confluence Whiteboards.',
		team: 'Design System Team',
	},
	story: {
		keywords: ['story', 'icon', 'core', 'bookmark', 'issue', 'jira status'],
		componentName: 'StoryIcon',
		package: '@atlaskit/icon/core/story',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for stories in Jira.',
		team: 'Design System Team',
	},
	subtasks: {
		keywords: ['subtasks', 'icon', 'core', 'issue', 'todo', 'checklist', 'jira status'],
		componentName: 'SubtasksIcon',
		package: '@atlaskit/icon/core/subtasks',
		oldName: ['subtask'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for subtasks in Jira.',
		team: 'Design System Team',
	},
	success: {
		keywords: ['success', 'icon', 'core', 'tick', 'completed', 'success', 'filled'],
		componentName: 'SuccessIcon',
		package: '@atlaskit/icon/core/success',
		oldName: ['check-circle', 'editor/success'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single-purpose - Reserved for success section messages and form validation messages.',
		team: 'Design System Team',
	},
	summarize: {
		keywords: ['summarize', 'icon', 'core', 'summarize', 'summarise', 'quote', 'quotes'],
		componentName: 'SummarizeIcon',
		package: '@atlaskit/icon/core/summarize',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for summarizing content with Atlassian Intelligence.',
		team: 'Atlassian Intelligence',
	},
	support: {
		keywords: [
			'support',
			'icon',
			'core',
			'support',
			'help',
			'life raft',
			'life ring',
			'lifebuoy',
			'life preserver',
		],
		componentName: 'SupportIcon',
		package: '@atlaskit/icon/core/support',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing help support.',
		team: 'Design System Team',
	},
	'table-cell-clear': {
		keywords: [
			'table-cell-clear',
			'tablecellclear',
			'icon',
			'core',
			'table',
			'cell',
			'clear',
			'empty',
		],
		componentName: 'TableCellClearIcon',
		package: '@atlaskit/icon/core/table-cell-clear',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for clearing the contents of table cells.',
		team: 'Editor',
	},
	'table-cell-merge': {
		keywords: [
			'table-cell-merge',
			'tablecellmerge',
			'icon',
			'core',
			'table',
			'cell',
			'merge',
			'combine',
			'join',
		],
		componentName: 'TableCellMergeIcon',
		package: '@atlaskit/icon/core/table-cell-merge',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for merging table cells.',
		team: 'Editor',
	},
	'table-cell-split': {
		keywords: [
			'table-cell-split',
			'tablecellsplit',
			'icon',
			'core',
			'table',
			'cell',
			'split',
			'divide',
			'separate',
		],
		componentName: 'TableCellSplitIcon',
		package: '@atlaskit/icon/core/table-cell-split',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for splitting joined table cells.',
		team: 'Editor',
	},
	'table-column-add-left': {
		keywords: [
			'table-column-add-left',
			'tablecolumnaddleft',
			'icon',
			'core',
			'table',
			'column',
			'add',
			'plus',
			'left',
			'before',
		],
		componentName: 'TableColumnAddLeftIcon',
		package: '@atlaskit/icon/core/table-column-add-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for adding a table column to the left of the selected column.',
		team: 'Editor',
	},
	'table-column-add-right': {
		keywords: [
			'table-column-add-right',
			'tablecolumnaddright',
			'icon',
			'core',
			'table',
			'column',
			'add',
			'right',
			'after',
		],
		componentName: 'TableColumnAddRightIcon',
		package: '@atlaskit/icon/core/table-column-add-right',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for adding a table column to the right of the selected column.',
		team: 'Editor',
	},
	'table-column-delete': {
		keywords: [
			'table-column-delete',
			'tablecolumndelete',
			'icon',
			'core',
			'table',
			'column',
			'delete',
			'remove',
			'x',
		],
		componentName: 'TableColumnDeleteIcon',
		package: '@atlaskit/icon/core/table-column-delete',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for deleting a table column.',
		team: 'Editor',
	},
	'table-column-move-left': {
		keywords: [
			'table-column-move-left',
			'tablecolumnmoveleft',
			'icon',
			'core',
			'table',
			'column',
			'move',
			'left',
			'arrow',
		],
		componentName: 'TableColumnMoveLeftIcon',
		package: '@atlaskit/icon/core/table-column-move-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for moving a table column to the left of its current location.',
		team: 'Editor',
	},
	'table-column-move-right': {
		keywords: [
			'table-column-move-right',
			'tablecolumnmoveright',
			'icon',
			'core',
			'table',
			'column',
			'move',
			'right',
			'arrow',
		],
		componentName: 'TableColumnMoveRightIcon',
		package: '@atlaskit/icon/core/table-column-move-right',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for moving a table column to the right of its current location.',
		team: 'Editor',
	},
	'table-columns-distribute': {
		keywords: [
			'table-columns-distribute',
			'tablecolumnsdistribute',
			'icon',
			'core',
			'table',
			'columns',
			'distribute',
			'even',
			'equidistant',
		],
		componentName: 'TableColumnsDistributeIcon',
		package: '@atlaskit/icon/core/table-columns-distribute',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for distributing the width of table columns evenly.',
		team: 'Editor',
	},
	'table-row-add-above': {
		keywords: [
			'table-row-add-above',
			'tablerowaddabove',
			'icon',
			'core',
			'table',
			'row',
			'add',
			'plus',
			'above',
			'up',
		],
		componentName: 'TableRowAddAboveIcon',
		package: '@atlaskit/icon/core/table-row-add-above',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for adding a table row above the selected row.',
		team: 'Editor',
	},
	'table-row-add-below': {
		keywords: [
			'table-row-add-below',
			'tablerowaddbelow',
			'icon',
			'core',
			'table',
			'row',
			'add',
			'plus',
			'below',
			'down',
		],
		componentName: 'TableRowAddBelowIcon',
		package: '@atlaskit/icon/core/table-row-add-below',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for adding a table row below the selected row.',
		team: 'Editor',
	},
	'table-row-delete': {
		keywords: [
			'table-row-delete',
			'tablerowdelete',
			'icon',
			'core',
			'table',
			'row',
			'delete',
			'remove',
			'x',
		],
		componentName: 'TableRowDeleteIcon',
		package: '@atlaskit/icon/core/table-row-delete',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for deleting a table row.',
		team: 'Editor',
	},
	'table-row-move-down': {
		keywords: [
			'table-row-move-down',
			'tablerowmovedown',
			'icon',
			'core',
			'table',
			'row',
			'move',
			'down',
			'arrow',
			'after',
		],
		componentName: 'TableRowMoveDownIcon',
		package: '@atlaskit/icon/core/table-row-move-down',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for moving a table row below its current location.',
		team: 'Editor',
	},
	'table-row-move-up': {
		keywords: [
			'table-row-move-up',
			'tablerowmoveup',
			'icon',
			'core',
			'table',
			'row',
			'move',
			'up',
			'arrow',
			'above',
		],
		componentName: 'TableRowMoveUpIcon',
		package: '@atlaskit/icon/core/table-row-move-up',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for moving a table row above its current location.',
		team: 'Editor',
	},
	tag: {
		keywords: ['tag', 'icon', 'core', 'label', 'topic'],
		componentName: 'TagIcon',
		package: '@atlaskit/icon/core/tag',
		oldName: ['label'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for tags in Atlas.',
		team: 'Design System Team',
	},
	'takeout-food': {
		keywords: [
			'takeout-food',
			'takeoutfood',
			'icon',
			'core',
			'takeaway',
			'takeout',
			'food',
			'burger',
			'drink',
		],
		componentName: 'TakeoutFoodIcon',
		package: '@atlaskit/icon/core/takeout-food',
		oldName: ['emoji/food'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Food emoji category.',
		team: 'Design System Team',
	},
	target: {
		keywords: ['target', 'icon', 'core', 'target', 'bullseye'],
		componentName: 'TargetIcon',
		package: '@atlaskit/icon/core/target',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for targets.',
		team: 'Design System Team',
	},
	task: {
		keywords: [
			'task',
			'check',
			'tick',
			'icon',
			'core',
			'single task',
			'todo',
			'list',
			'check mark',
			'tick',
		],
		componentName: 'TaskIcon',
		package: '@atlaskit/icon/core/task',
		oldName: ['task'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for tasks in Jira.',
		team: 'Design System Team',
	},
	'task-in-progress': {
		keywords: [
			'task-in-progress',
			'taskinprogress',
			'icon',
			'core',
			'calendar',
			'task',
			'status',
			'in progress',
		],
		componentName: 'TaskInProgressIcon',
		package: '@atlaskit/icon/core/task-in-progress',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for in progress task status. Used in JSM Calendar view.',
		team: 'JSM Calendar',
	},
	'task-to-do': {
		keywords: [
			'task-to-do',
			'tasktodo',
			'icon',
			'core',
			'calendar',
			'task',
			'to-do',
			'todo',
			'status',
		],
		componentName: 'TaskToDoIcon',
		package: '@atlaskit/icon/core/task-to-do',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for to-do task status. Used in JSM Calendar view.',
		team: 'JSM Calendar',
	},
	tasks: {
		keywords: ['tasks', 'icon', 'core', 'multiple tasks', 'todo', 'list', 'check mark', 'tick'],
		componentName: 'TasksIcon',
		package: '@atlaskit/icon/core/tasks',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for multiple tasks in Jira.',
		team: 'Design System Team',
	},
	teams: {
		keywords: ['teams', 'icon', 'core', 'infinite love', 'people', 'persons', 'customers', 'users'],
		componentName: 'TeamsIcon',
		package: '@atlaskit/icon/core/teams',
		oldName: ['teams'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for teams in Atlassian.',
		team: 'Design System Team',
	},
	text: {
		keywords: ['text', 'icon', 'core', 'character', 'font', 'letter', 'type', 'typography', 'text'],
		componentName: 'TextIcon',
		package: '@atlaskit/icon/core/text',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for representing text objects.',
		team: 'Design System Team',
	},
	'text-bold': {
		keywords: ['text-bold', 'textbold', 'icon', 'core', 'text', 'type', 'bold', 'font'],
		componentName: 'TextBoldIcon',
		package: '@atlaskit/icon/core/text-bold',
		oldName: ['editor/bold'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for bolding text.',
		team: 'Design System Team',
	},
	'text-indent-left': {
		keywords: [
			'text-indent-left',
			'textindentleft',
			'icon',
			'core',
			'text',
			'outdent',
			'left',
			'arrow',
		],
		componentName: 'TextIndentLeftIcon',
		package: '@atlaskit/icon/core/text-indent-left',
		oldName: ['editor/outdent'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for shifting the indent of text content left.',
		team: 'Design System Team',
	},
	'text-indent-right': {
		keywords: [
			'text-indent-right',
			'textindentright',
			'icon',
			'core',
			'text',
			'indent',
			'right',
			'arrow',
		],
		componentName: 'TextIndentRightIcon',
		package: '@atlaskit/icon/core/text-indent-right',
		oldName: ['editor/indent'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for shifting the indent of text content right.',
		team: 'Design System Team',
	},
	'text-italic': {
		keywords: ['text-italic', 'textitalic', 'icon', 'core', 'text', 'type', 'italic', 'font'],
		componentName: 'TextItalicIcon',
		package: '@atlaskit/icon/core/text-italic',
		oldName: ['editor/italic'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for italicizing text.',
		team: 'Design System Team',
	},
	'text-shorten': {
		keywords: [
			'text-shorten',
			'textshorten',
			'icon',
			'core',
			'text',
			'shorten',
			'abbreviate',
			'condense',
			'AI',
		],
		componentName: 'TextShortenIcon',
		package: '@atlaskit/icon/core/text-shorten',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for shortening text content with Atlassian Intelligence.',
		team: 'Atlassian Intelligence',
	},
	'text-spellcheck': {
		keywords: [
			'text-spellcheck',
			'textspellcheck',
			'icon',
			'core',
			'text',
			'spelling',
			'typo',
			'spellcheck',
		],
		componentName: 'TextSpellcheckIcon',
		package: '@atlaskit/icon/core/text-spellcheck',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for spellchecking.',
		team: 'Design System Team',
	},
	'text-style': {
		keywords: [
			'text-style',
			'textstyle',
			'icon',
			'core',
			'characters',
			'font',
			'letters',
			'type',
			'typography',
		],
		componentName: 'TextStyleIcon',
		package: '@atlaskit/icon/core/text-style',
		oldName: ['editor/text-style', 'emoji/keyboard'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for text styles in Editor.',
		team: 'Editor',
	},
	'text-wrap': {
		keywords: ['text-wrap', 'textwrap', 'icon', 'core', 'text', 'wrap', 'line wrap'],
		componentName: 'TextWrapIcon',
		package: '@atlaskit/icon/core/text-wrap',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for wrapping text so it spans multiple lines.',
		team: 'Design System Team',
	},
	theme: {
		keywords: ['theme', 'icon', 'core', 'theme', 'light mode', 'dark mode', 'theme switcher'],
		componentName: 'ThemeIcon',
		package: '@atlaskit/icon/core/theme',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for representing themes and theme switching.',
		team: 'Design System Team',
	},
	'thumbs-down': {
		keywords: [
			'thumbs-down',
			'thumbsdown',
			'icon',
			'core',
			'vote',
			'downvote',
			'dislike',
			'feedback',
			'hand',
		],
		componentName: 'ThumbsDownIcon',
		package: '@atlaskit/icon/core/thumbs-down',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: voting, feedback in AI.',
		team: 'Design System Team',
	},
	'thumbs-up': {
		keywords: [
			'thumbs-up',
			'thumbsup',
			'icon',
			'core',
			'vote',
			'upvote',
			'like',
			'feedback',
			'hand',
		],
		componentName: 'ThumbsUpIcon',
		package: '@atlaskit/icon/core/thumbs-up',
		oldName: ['like'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known uses: voting options in Jira, like.',
		team: 'Design System Team',
	},
	timeline: {
		keywords: ['timeline', 'icon', 'core', 'gantt', 'calendar'],
		componentName: 'TimelineIcon',
		package: '@atlaskit/icon/core/timeline',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for timelines in Jira or Trello.',
		team: 'Design System Team',
	},
	tools: {
		keywords: ['tools', 'icon', 'core', 'tools', 'wrench', 'spanner', 'screwdriver'],
		componentName: 'ToolsIcon',
		package: '@atlaskit/icon/core/tools',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Multi purpose - Known usages: 'Service request' Jira issue type.",
		team: 'Design System Team',
	},
	transition: {
		keywords: ['transition', 'icon', 'core', 'connector', 'movement'],
		componentName: 'TransitionIcon',
		package: '@atlaskit/icon/core/transition',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for transitions in Analytics.',
		team: 'Design System Team',
	},
	translate: {
		keywords: [
			'translate',
			'icon',
			'core',
			'translate',
			'language',
			'i18n',
			'internationalization',
			'internationalisation',
		],
		componentName: 'TranslateIcon',
		package: '@atlaskit/icon/core/translate',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing language translations.',
		team: 'Design System Team',
	},
	tree: {
		keywords: ['tree', 'icon', 'core', 'tree', 'nature'],
		componentName: 'TreeIcon',
		package: '@atlaskit/icon/core/tree',
		oldName: ['emoji/nature'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Nature emoji category.',
		team: 'Design System Team',
	},
	undo: {
		keywords: ['undo', 'icon', 'core', 'backward', 'mistake', 'correct'],
		componentName: 'UndoIcon',
		package: '@atlaskit/icon/core/undo',
		oldName: ['editor/undo', 'undo'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for undo in Editor.',
		team: 'Design System Team',
	},
	upload: {
		keywords: ['upload', 'cloud', 'icon', 'core', 'stream', 'file'],
		componentName: 'UploadIcon',
		package: '@atlaskit/icon/core/upload',
		oldName: ['export'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for upload in Editor.',
		team: 'Design System Team',
	},
	'vehicle-car': {
		keywords: ['vehicle-car', 'vehiclecar', 'icon', 'core', 'car', 'transportation', 'vehicle'],
		componentName: 'VehicleCarIcon',
		package: '@atlaskit/icon/core/vehicle-car',
		oldName: ['emoji/travel'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose - Known usages: Transport emoji category.',
		team: 'Design System Team',
	},
	video: {
		keywords: ['video', 'icon', 'core', 'media', 'movie'],
		componentName: 'VideoIcon',
		package: '@atlaskit/icon/core/video',
		oldName: [
			'media-services/video',
			'hipchat/sd-video',
			'vid-camera-on',
			'video-circle',
			'video-filled',
		],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for representing video content across Atlassian and Loom.',
		team: 'Design System Team',
	},
	'video-next': {
		keywords: ['video-next', 'videonext', 'icon', 'core', 'skip', 'next', 'media', 'forward'],
		componentName: 'VideoNextIcon',
		package: '@atlaskit/icon/core/video-next',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for next video in Media.',
		team: 'Design System Team',
	},
	'video-next-overlay': {
		keywords: [
			'video-next-overlay',
			'videonextoverlay',
			'icon',
			'core',
			'skip',
			'next',
			'media',
			'forward',
			'filled',
		],
		componentName: 'VideoNextOverlayIcon',
		package: '@atlaskit/icon/core/video-next-overlay',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for next video when icon is overlayed on Media.',
		team: 'Design System Team',
	},
	'video-pause': {
		keywords: ['video-pause', 'videopause', 'icon', 'core', 'media', 'video'],
		componentName: 'VideoPauseIcon',
		package: '@atlaskit/icon/core/video-pause',
		oldName: ['vid-pause'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for pause video in Media.',
		team: 'Design System Team',
	},
	'video-pause-overlay': {
		keywords: [
			'video-pause-overlay',
			'videopauseoverlay',
			'icon',
			'core',
			'media',
			'video',
			'filled',
		],
		componentName: 'VideoPauseOverlayIcon',
		package: '@atlaskit/icon/core/video-pause-overlay',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for pause video when icon is overlayed on Media.',
		team: 'Design System Team',
	},
	'video-play': {
		keywords: ['video-play', 'videoplay', 'icon', 'core', 'media', 'video'],
		componentName: 'VideoPlayIcon',
		package: '@atlaskit/icon/core/video-play',
		oldName: ['vid-play'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for play video in Media.',
		team: 'Design System Team',
	},
	'video-play-overlay': {
		keywords: [
			'video-play-overlay',
			'videoplayoverlay',
			'icon',
			'core',
			'media',
			'video',
			'filled',
		],
		componentName: 'VideoPlayOverlayIcon',
		package: '@atlaskit/icon/core/video-play-overlay',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for play video when icon is overlayed on Media.',
		team: 'Design System Team',
	},
	'video-previous': {
		keywords: [
			'video-previous',
			'videoprevious',
			'icon',
			'core',
			'media',
			'video',
			'back',
			'previous',
		],
		componentName: 'VideoPreviousIcon',
		package: '@atlaskit/icon/core/video-previous',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for previous video in Media.',
		team: 'Design System Team',
	},
	'video-previous-overlay': {
		keywords: [
			'video-previous-overlay',
			'videopreviousoverlay',
			'icon',
			'core',
			'media',
			'video',
			'back',
			'previous',
			'filled',
		],
		componentName: 'VideoPreviousOverlayIcon',
		package: '@atlaskit/icon/core/video-previous-overlay',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for previous video when icon is overlayed on Media.',
		team: 'Design System Team',
	},
	'video-skip-backward-fifteen': {
		keywords: [
			'video-skip-backward-fifteen',
			'videoskipbackwardfifteen',
			'icon',
			'core',
			'video',
			'media',
			'skip',
			'backwards',
			'15',
			'seconds',
		],
		componentName: 'VideoSkipBackwardFifteenIcon',
		package: '@atlaskit/icon/core/video-skip-backward-fifteen',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for skipping video content backward by 15 seconds.',
		team: 'Design System Team',
	},
	'video-skip-backward-ten': {
		keywords: [
			'video-skip-backward-ten',
			'videoskipbackwardten',
			'icon',
			'core',
			'video',
			'media',
			'skip',
			'backwards',
			'10',
			'seconds',
		],
		componentName: 'VideoSkipBackwardTenIcon',
		package: '@atlaskit/icon/core/video-skip-backward-ten',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for skipping video content backwards by 10 seconds.',
		team: 'Design System Team',
	},
	'video-skip-forward-fifteen': {
		keywords: [
			'video-skip-forward-fifteen',
			'videoskipforwardfifteen',
			'icon',
			'core',
			'video',
			'media',
			'skip',
			'forward',
			'15',
			'seconds',
		],
		componentName: 'VideoSkipForwardFifteenIcon',
		package: '@atlaskit/icon/core/video-skip-forward-fifteen',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for skipping video content forward by 15 seconds.',
		team: 'Design System Team',
	},
	'video-skip-forward-ten': {
		keywords: [
			'video-skip-forward-ten',
			'videoskipforwardten',
			'icon',
			'core',
			'video',
			'media',
			'skip',
			'forward',
			'10',
			'seconds',
		],
		componentName: 'VideoSkipForwardTenIcon',
		package: '@atlaskit/icon/core/video-skip-forward-ten',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for skipping video content forward by 10 seconds.',
		team: 'Design System Team',
	},
	'video-stop': {
		keywords: ['video-stop', 'videostop', 'icon', 'core', 'video', 'stop'],
		componentName: 'VideoStopIcon',
		package: '@atlaskit/icon/core/video-stop',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for stopping video content.',
		team: 'Design System Team',
	},
	'video-stop-overlay': {
		keywords: [
			'video-stop-overlay',
			'videostopoverlay',
			'icon',
			'core',
			'video',
			'stop',
			'overlay',
			'inverse',
		],
		componentName: 'VideoStopOverlayIcon',
		package: '@atlaskit/icon/core/video-stop-overlay',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for stopping video content. Use on dark or translucent backgrounds.',
		team: 'Design System Team',
	},
	'volume-high': {
		keywords: ['volume-high', 'volumehigh', 'icon', 'core', 'louder', 'volume up'],
		componentName: 'VolumeHighIcon',
		package: '@atlaskit/icon/core/volume-high',
		oldName: ['hipchat/outgoing-sound', 'vid-volume-full'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: '📦 @atlaskit/icon/core/volume-high',
		team: 'Design System Team',
	},
	'volume-low': {
		keywords: ['volume-low', 'volumelow', 'icon', 'core', 'quiter', 'volume down'],
		componentName: 'VolumeLowIcon',
		package: '@atlaskit/icon/core/volume-low',
		oldName: ['vid-volume-half'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: '📦 @atlaskit/icon/core/volume-low',
		team: 'Design System Team',
	},
	'volume-muted': {
		keywords: ['volume-muted', 'volumemuted', 'icon', 'core', 'volume off', 'no volume'],
		componentName: 'VolumeMutedIcon',
		package: '@atlaskit/icon/core/volume-muted',
		oldName: ['vid-volume-muted'],
		type: 'core',
		categorization: 'multi-purpose',
		usage: '📦 @atlaskit/icon/core/volume-muted',
		team: 'Design System Team',
	},
	warning: {
		keywords: [
			'warning',
			'error',
			'alert',
			'icon',
			'core',
			'alert',
			'danger',
			'triangle',
			'filled',
		],
		componentName: 'WarningIcon',
		package: '@atlaskit/icon/core/warning',
		oldName: ['editor/warning', 'warning'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for warning system status.',
		team: 'Design System Team',
	},
	whiteboard: {
		keywords: ['whiteboard', 'icon', 'core', 'shapes', 'canvas'],
		componentName: 'WhiteboardIcon',
		package: '@atlaskit/icon/core/whiteboard',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for whiteboards in Confluence.',
		team: 'Design System Team',
	},
	'zoom-in': {
		keywords: ['zoom-in', 'zoomin', 'icon', 'core', 'magnify', 'magnifying glass'],
		componentName: 'ZoomInIcon',
		package: '@atlaskit/icon/core/zoom-in',
		oldName: ['media-services/zoom-in'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for zooming in of an object or view.',
		team: 'Design System Team',
	},
	'zoom-out': {
		keywords: ['zoom-out', 'zoomout', 'icon', 'core', 'minify', 'magnifying glass'],
		componentName: 'ZoomOutIcon',
		package: '@atlaskit/icon/core/zoom-out',
		oldName: ['media-services/zoom-out'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for zooming out of an object or view.',
		team: 'Design System Team',
	},
};

export default metadata;
