/* eslint-disable @repo/internal/react/no-css-string-literals */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { ButtonGroup } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

/**
 * __Example action container__.
 *
 * An example action container sits at the top right of an example container and displays various actions for the user.
 *
 */
export const ExampleActionContainer = ({ children }: { children: React.ReactNode }) => {
	return (
		/**
		 * This element uses an `onMouseDown` to block event propagation
		 * to its parent but is not itself interactive.
		 */
		/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
		<div
			// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			css={css`
				position: absolute;
				top: 0;
				right: 0;
				padding: ${token('space.050', '4px')};

				z-index: 1;
			`}
			// Stops the code expanding when you click an action
			onMouseDown={stopPropagation}
		>
			<ButtonGroup label="Example actions">{children}</ButtonGroup>
		</div>
	);
};
