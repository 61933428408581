import React from 'react';

import { cssMap } from '@atlaskit/css';
import { IconTile } from '@atlaskit/icon';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import { Box, Grid } from '@atlaskit/primitives/compiled';

import BeforeAfterCard from './card';

export type BeforeAfterContent = {
	content?: React.ReactNode;
	image?: {
		url: string;
		alt: string;
	};
};

export type BeforeAfterProps = {
	before: BeforeAfterContent;
	after: BeforeAfterContent;
	testId?: string;
	isFullWidth?: boolean;
};

const styles = cssMap({
	arrowIcon: {
		display: 'none',
		'@media (min-width: 48rem)': {
			display: 'initial',
		},
	},
	grid: {
		gridTemplateColumns: '1fr 1fr',

		'@media (min-width: 48rem)': {
			gridTemplateColumns: '1fr auto 1fr',
		},
	},
	gridFullWidth: {
		gridTemplateColumns: 'auto',
	},
});

/**
 * __BeforeAfter__
 *
 * A BeforeAfter displays a before and after comparison in the form of two adjacent cards.
 *
 */
const BeforeAfter = ({ before, after, testId, isFullWidth }: BeforeAfterProps) => {
	return (
		<Grid
			testId={testId}
			gap="space.200"
			alignItems="center"
			xcss={isFullWidth ? styles.gridFullWidth : styles.grid}
		>
			<BeforeAfterCard type="before" image={before.image} isFullWidth={isFullWidth}>
				{before.content}
			</BeforeAfterCard>
			{!isFullWidth && (
				<Box xcss={styles.arrowIcon}>
					<IconTile appearance="purple" size="48" icon={ArrowRightIcon} label="" />
				</Box>
			)}
			<BeforeAfterCard type="after" image={after.image} isFullWidth={isFullWidth}>
				{after.content}
			</BeforeAfterCard>
		</Grid>
	);
};

export default BeforeAfter;
