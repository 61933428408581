import React, {
	type SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';

import SearchIcon from '@atlaskit/icon/glyph/search';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Show, UNSAFE_useMediaQuery } from '@atlaskit/primitives/responsive';
import Textfield from '@atlaskit/textfield';

import {
	categories,
	getGlobalIcons,
	getObjectIcons,
	iconObjectImportPrefix,
	legacyIconImportPrefix,
	sortIntoCategories,
} from '../helpers';
import { type AllIconsList, type IconData, type IconsList } from '../types';

import GridSection from './grid-section';
import LegacyIconDetails from './legacy-icon-details';

const fuseOptions: Fuse.IFuseOptions<IconData> = {
	keys: [
		{
			name: 'componentName',
			weight: 1,
		},
		{
			name: 'packageName',
			weight: 1,
		},
		{
			name: 'iconName',
			weight: 1,
		},
		{
			name: 'keywords',
			weight: 1,
		},
	],
	useExtendedSearch: true,
	threshold: 0.4,
	ignoreLocation: true,
};

const LegacyIconDetailsBoxStyles = xcss({
	position: 'sticky',
	insetBlockStart: '64px',
});

const modalIconDetailsStyles = xcss({
	width: '100%',
	borderRadius: '4px',
	overflow: 'auto',
});

type IconExplorerProps = {
	iconType: 'global' | 'object';
	testId?: string;
};

const SearchIconBefore = () => (
	<Box paddingInlineStart="space.100">
		<SearchIcon label="Search" size="small" />
	</Box>
);

export function LegacyIconExplorer({ iconType, testId }: IconExplorerProps) {
	const [selectedIcon, setSelectedIcon] = useState<IconData | undefined>();
	const [icons, setIcons] = useState<AllIconsList>();
	const [allIcons, setAllIcons] = useState<AllIconsList>();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isBelowSm = UNSAFE_useMediaQuery('below.sm');

	const fuseIndex = useRef<Fuse<IconData> | null>(null);

	const search = useCallback(
		(query: string) => {
			if (fuseIndex.current) {
				const results = fuseIndex.current.search<IconData>(query).map((result) => result.item);
				setIcons(sortIntoCategories(results, iconType));
			}
		},
		[iconType],
	);

	const debouncedSearch = useMemo(() => debounce(search, 300), [search]);
	const handleSearch = useCallback(
		(query: string, opts: { isDebounced?: boolean } = { isDebounced: false }) => {
			if (query !== '') {
				opts.isDebounced ? debouncedSearch(query) : search(query);
			} else {
				setIcons(allIcons);
			}
		},
		[debouncedSearch, allIcons, search],
	);

	useEffect(() => {
		const iconPromise = iconType === 'global' ? getGlobalIcons() : getObjectIcons();
		iconPromise.then((icons) => {
			const typedIcons = icons as IconsList;
			fuseIndex.current = new Fuse(Object.values(typedIcons), fuseOptions);
			setAllIcons(sortIntoCategories(Object.values(typedIcons), iconType));
			setIcons(sortIntoCategories(Object.values(typedIcons), iconType));
		});
	}, [iconType]);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedIcon(undefined);
	};

	const updateSelectedIcon = useCallback(
		(icon: IconData) => {
			setSelectedIcon((oldIcon) => (oldIcon?.packageName !== icon.packageName ? icon : undefined));
			isBelowSm?.matches && setIsModalOpen(true);
		},
		[setSelectedIcon, isBelowSm?.matches],
	);

	if (!icons) {
		return null;
	}

	return (
		<Inline space="space.200" testId={testId}>
			<Stack space="space.400" grow="fill">
				<Textfield
					placeholder="Search"
					elemBeforeInput={<SearchIconBefore />}
					onChange={(evt: SyntheticEvent<HTMLInputElement>) =>
						handleSearch(evt.currentTarget.value, {
							isDebounced: true,
						})
					}
				/>
				{iconType === 'global' ? (
					<>
						{icons.global.length > 0 && (
							<GridSection
								isLegacy={true}
								importPath={legacyIconImportPrefix}
								heading="Global icons"
								icons={icons?.global}
								onIconClick={updateSelectedIcon}
								selectedIcon={!isModalOpen ? selectedIcon?.packageName : undefined}
							/>
						)}

						{categories.map((category) => {
							return (
								icons[category].length > 0 && (
									<GridSection
										isLegacy={true}
										importPath={`${legacyIconImportPrefix}/${category}`}
										heading={category}
										icons={icons?.[category]}
										key={category}
										onIconClick={updateSelectedIcon}
										selectedIcon={!isModalOpen ? selectedIcon?.packageName : undefined}
									/>
								)
							);
						})}
					</>
				) : (
					<>
						<GridSection
							isLegacy={true}
							importPath={`${iconObjectImportPrefix}/.../16`}
							heading="Small"
							icons={icons?.['16']}
							onIconClick={updateSelectedIcon}
							selectedIcon={selectedIcon?.packageName}
						/>

						<GridSection
							isLegacy={true}
							importPath={`${iconObjectImportPrefix}/.../24`}
							heading="Medium"
							icons={icons?.['24']}
							onIconClick={updateSelectedIcon}
							selectedIcon={selectedIcon?.packageName}
						/>
					</>
				)}
			</Stack>
			<ModalTransition>
				{isModalOpen && selectedIcon && (
					<Modal onClose={closeModal}>
						<Box xcss={modalIconDetailsStyles}>
							<LegacyIconDetails icon={selectedIcon} isModal={true} onClose={closeModal} />
						</Box>
					</Modal>
				)}
			</ModalTransition>
			<Show above="sm">
				<Box xcss={LegacyIconDetailsBoxStyles}>
					<LegacyIconDetails icon={selectedIcon} />
				</Box>
			</Show>
		</Inline>
	);
}
