import React from 'react';

import { cssMap } from '@atlaskit/css';
import Heading from '@atlaskit/heading';
import { Box, Stack, Text } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';

import type { BeforeAfterContent } from './index';

const styles = cssMap({
	card: {
		borderRadius: token('border.radius'),
		borderColor: token('color.border'),
		borderWidth: token('border.width'),
		borderStyle: 'solid',
		display: 'flex',
		flexDirection: 'column',
		gridColumn: 'span 2',
		'@media (min-width: 48rem)': {
			gridColumn: 'span 1',
		},
	},
	imageContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
		borderBlockEndStyle: 'solid',
		borderBlockEndWidth: token('border.width'),
		borderBlockEndColor: token('color.border'),
	},
	image: {
		maxWidth: '100%',
	},
	contentContainer: {
		padding: token('space.200'),
	},
});

type BeforeAfterCardProps = Omit<BeforeAfterContent, 'content'> & {
	type: 'before' | 'after';
	isFullWidth?: boolean;
	children?: React.ReactNode;
};

/**
 * __BeforeAfterCard__
 *
 * A single card that displays before or after content.
 */
const BeforeAfterCard = ({ type, image, children }: BeforeAfterCardProps) => {
	return (
		<Box xcss={styles.card}>
			{image && (
				<Box xcss={styles.imageContainer}>
					<Box as="img" src={image.url} alt={image.alt || ''} xcss={styles.image} />
				</Box>
			)}

			<Stack space="space.150" xcss={styles.contentContainer}>
				<Heading size="small" as="h3">
					{type === 'before' ? 'Before' : 'After'}
				</Heading>
				<Text as="p">{children}</Text>
			</Stack>
		</Box>
	);
};

export default BeforeAfterCard;
