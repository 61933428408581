import IconTileShape from "../../../../../../packages/design-system/icon/examples/constellation/icon-tile-shape.tsx";
import IconTileSize from "../../../../../../packages/design-system/icon/examples/constellation/icon-tile-size.tsx";
import IconTileAppearance from "../../../../../../packages/design-system/icon/examples/constellation/icon-tile-appearance.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_tile_shape_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-tile-shape.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_tile_size_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-tile-size.tsx";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_tile_appearance_tsx from "!!raw-loader!../../../../../../packages/design-system/icon/examples/constellation/icon-tile-appearance.tsx";
import * as React from 'react';
export default {
  IconTileShape,
  IconTileSize,
  IconTileAppearance,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_tile_shape_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_tile_size_tsx,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHicon_tile_appearance_tsx,
  React
};