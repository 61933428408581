import React from 'react';

import { useThemeObserver } from '@atlaskit/tokens';

// Same colors as defined by @atlaskit/logo
const colorMap: Record<'light' | 'dark', Record<'iconColor' | 'textColor', string>> = {
	light: {
		iconColor: '#1868DB',
		textColor: '#101214',
	},
	dark: {
		iconColor: '#357DE8',
		textColor: '#E2E3E4',
	},
};

const svg = (colorMode: 'light' | 'dark') => {
	const colors = colorMap[colorMode];

	return (
		<svg
			width="304"
			height="24"
			viewBox="0 0 304 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_134_20771)">
				<path
					d="M5.29336 8.42926C5.23877 8.36022 5.16794 8.30658 5.08757 8.27341C5.00719 8.24023 4.9199 8.22861 4.83394 8.23964C4.74797 8.25067 4.66616 8.28398 4.59621 8.33644C4.52626 8.3889 4.47048 8.45878 4.43413 8.53949L0.0574521 17.4681C0.0160504 17.5524 -0.00355071 17.6462 0.00052814 17.7405C0.00460699 17.8349 0.032229 17.9265 0.0807456 18.0068C0.129262 18.087 0.197047 18.1531 0.277599 18.1987C0.358151 18.2443 0.44877 18.2679 0.540766 18.2672H6.60905C6.70598 18.2695 6.80134 18.2419 6.88275 18.1878C6.96415 18.1337 7.02783 18.0558 7.06551 17.9641C8.3812 15.1808 7.57567 10.9645 5.29336 8.42926Z"
					fill="url(#paint0_linear_134_20771)"
				/>
				<path
					d="M8.48853 0.299776C7.40045 2.01585 6.76638 3.99306 6.64868 6.03692C6.53099 8.08078 6.93372 10.1209 7.81726 11.9565L10.744 17.9641C10.7869 18.0517 10.8518 18.126 10.9321 18.1793C11.0123 18.2326 11.105 18.2629 11.2005 18.2672H17.2687C17.3607 18.2678 17.4514 18.2442 17.5319 18.1986C17.6125 18.153 17.6802 18.0869 17.7288 18.0067C17.7773 17.9265 17.8049 17.8348 17.809 17.7405C17.8131 17.6462 17.7935 17.5523 17.7521 17.468C17.7521 17.468 9.56256 0.713136 9.37461 0.299776C9.18665 -0.113585 8.73019 -0.0860278 8.48853 0.299776Z"
					fill="#2684FF"
				/>
				<path
					d="M79.6701 7.46469C79.6701 9.69684 80.6636 11.4605 84.5839 12.2321C86.9199 12.7282 87.4032 13.114 87.4032 13.9131C87.4032 14.7123 86.9199 15.2083 85.2551 15.2083C83.2676 15.1598 81.322 14.611 79.5896 13.61V17.22C80.771 17.8263 82.3284 18.4876 85.2283 18.4876C89.3096 18.4876 90.9475 16.6137 90.9475 13.8304L79.6701 7.46469ZM90.9475 13.8304C90.9475 11.2125 89.5781 9.97242 85.7653 9.11814C83.6441 8.64966 83.1339 8.15363 83.1339 7.46469C83.1339 6.77576 83.8857 6.25217 85.282 6.25217C86.6782 6.25217 88.6383 6.77576 90.2225 7.49225V4.04758C89.1216 3.46887 87.4032 3.02795 85.3894 3.02795C81.6303 3.02795 79.6701 4.70895 79.6701 7.46469"
					fill="#0052CC"
				/>
				<path
					d="M132.244 3.24841V18.2672H135.359V6.80332L136.674 9.86219L141.078 18.2672H144.998V3.24841H141.883V12.9211L140.702 10.1378L137.158 3.24841H132.244Z"
					fill="#0052CC"
				/>
				<path d="M112.455 3.24841H109.045V18.2507H112.455V3.24841Z" fill="#0052CC" />
				<path
					d="M105.125 13.8304C105.125 11.2125 103.755 9.97242 99.9157 9.11814C97.8213 8.64966 97.3112 8.15363 97.3112 7.46469C97.3112 6.77576 98.063 6.25217 99.4592 6.25217C100.855 6.25217 102.816 6.77576 104.4 7.49225V4.04758C103.299 3.46887 101.58 3.02795 99.5666 3.02795C95.7807 3.02795 93.8206 4.70895 93.8206 7.46469C93.8206 9.69684 94.8409 11.4605 98.7611 12.2321C101.097 12.7282 101.58 13.114 101.58 13.9131C101.58 14.7123 101.097 15.2083 99.4324 15.2083C97.4443 15.1632 95.498 14.6141 93.7668 13.61V17.22C94.9483 17.8263 96.5056 18.4876 99.4055 18.4876C103.487 18.4876 105.125 16.6137 105.125 13.8304Z"
					fill="#0052CC"
				/>
				<path
					d="M53.2222 3.24841V18.2672H60.2034L61.3043 15.0154H56.6322V3.24841H53.2222Z"
					fill="#0052CC"
				/>
				<path
					d="M39.394 3.24902V6.50079H43.18V18.2678H46.6169V6.50079H50.6445V3.24902H39.394Z"
					fill="#0052CC"
				/>
				<path
					d="M34.4265 3.24902H29.9425L24.8408 18.2678H28.7342L29.4592 15.7325C31.2415 16.2468 33.1275 16.2468 34.9099 15.7325L35.6348 18.2678H39.5282L34.4265 3.24902ZM32.1711 13.0319C31.5443 13.0255 30.9212 12.9328 30.3184 12.7563L32.1711 6.19766L34.0506 12.7563C33.4391 12.9346 32.8068 13.0273 32.1711 13.0319Z"
					fill="#0052CC"
				/>
				<path
					d="M72.2862 3.24829H67.7752L62.7004 18.2671H66.5938L67.3188 15.7318C68.2013 15.9872 69.1138 16.117 70.0307 16.1176C70.9563 16.1151 71.8774 15.9853 72.7695 15.7318L73.4676 18.2671H77.361L72.2862 3.24829ZM70.0307 13.0312C69.3951 13.0246 68.7631 12.9319 68.1511 12.7556L70.0307 6.19693L71.9103 12.7556C71.2983 12.9319 70.6663 13.0246 70.0307 13.0312V13.0312Z"
					fill="#0052CC"
				/>
				<path
					d="M124.699 3.24829H120.215L115.113 18.2671H119.007L119.732 15.7318C120.623 15.987 121.545 16.1168 122.47 16.1176C123.387 16.117 124.3 15.9872 125.182 15.7318L125.907 18.2671H129.801L124.699 3.24829ZM122.47 13.0312C121.835 13.0246 121.203 12.9319 120.591 12.7556L122.47 6.19693L124.35 12.7556C123.738 12.9301 123.106 13.0227 122.47 13.0312V13.0312Z"
					fill="#0052CC"
				/>
				<path
					d="M154.154 2.14612H159.954C165.029 2.14612 167.499 5.39789 167.499 10.2204C167.499 15.043 165.002 18.2396 159.954 18.2396H154.154V2.14612ZM156.302 4.24048V16.1453H159.981C163.552 16.1453 165.351 14.2163 165.351 10.3031C165.351 6.38995 163.659 4.24048 159.846 4.24048H156.302Z"
					fill={colors.textColor}
				/>
				<path
					d="M175.715 18.4877C171.258 18.4877 169.298 15.8422 169.298 12.0944C169.298 8.34659 171.285 5.72864 174.937 5.72864C178.588 5.72864 180.092 8.37414 180.092 12.0944V13.0589H171.365C171.661 15.1257 172.976 16.476 175.796 16.476C177.033 16.4842 178.262 16.26 179.421 15.8146V17.7712C178.454 18.2948 176.95 18.4877 175.715 18.4877ZM171.339 11.2401H178.024C177.89 8.95285 176.897 7.65765 174.829 7.65765C172.762 7.65765 171.553 9.09064 171.339 11.2401Z"
					fill={colors.textColor}
				/>
				<path
					d="M186.294 18.4877C184.857 18.5242 183.431 18.2127 182.133 17.5783V15.3462C183.427 16.1038 184.885 16.5204 186.375 16.5587C187.959 16.5587 188.765 15.8973 188.765 14.9328C188.765 13.9683 188.067 13.4723 185.811 12.8936C183.556 12.3149 182.025 11.185 182.025 9.17332C182.025 7.16163 183.636 5.72864 186.348 5.72864C187.689 5.71397 189.014 6.02562 190.215 6.63804V8.81507C188.765 8.07102 187.61 7.68522 186.321 7.68522C185.032 7.68522 184.012 8.20881 184.012 9.17332C184.012 10.1378 184.576 10.5787 186.778 11.1023C189.436 11.7637 190.779 12.7833 190.779 14.9053C190.779 17.0272 189.49 18.4877 186.294 18.4877Z"
					fill={colors.textColor}
				/>
				<path
					d="M194.189 1.26424C194.382 1.25218 194.576 1.2824 194.757 1.35291C194.938 1.42343 195.103 1.53263 195.24 1.6733C195.377 1.81396 195.483 1.98289 195.552 2.1689C195.621 2.35491 195.65 2.55378 195.639 2.75234C195.639 3.68929 195.021 4.21288 194.189 4.21288C193.998 4.22966 193.805 4.2034 193.625 4.13593C193.445 4.06847 193.282 3.96143 193.146 3.82231C193.011 3.68318 192.906 3.51534 192.841 3.33052C192.775 3.1457 192.749 2.94837 192.766 2.75234C192.75 2.55518 192.775 2.35683 192.841 2.17079C192.907 1.98475 193.01 1.81538 193.145 1.67419C193.28 1.533 193.443 1.42331 193.624 1.35257C193.804 1.28183 193.996 1.25171 194.189 1.26424V1.26424ZM193.141 5.97656H195.209V18.2396H193.141V5.97656Z"
					fill={colors.textColor}
				/>
				<path
					d="M206.352 16.0351C205.6 17.661 204.15 18.4877 202.324 18.4877C199.156 18.4877 197.599 15.732 197.599 12.122C197.599 8.51193 199.237 5.72864 202.566 5.72864C204.311 5.72864 205.654 6.55536 206.352 8.15369V5.97665H208.366V17.1374C208.366 20.7474 206.728 23.1725 202.432 23.1725C200.445 23.1725 199.344 22.9244 198.189 22.5111V20.4718C199.531 20.9371 200.936 21.179 202.351 21.1883C205.332 21.1883 206.352 19.5624 206.352 17.2752V16.0351ZM202.861 16.5311C204.714 16.5311 206.352 15.3462 206.352 12.618V11.6259C206.352 8.89774 204.848 7.71277 203.103 7.71277C200.794 7.71277 199.586 9.28354 199.586 12.122C199.586 14.9604 200.74 16.5311 202.861 16.5311Z"
					fill={colors.textColor}
				/>
				<path
					d="M221.63 18.2396H219.589V10.8542C219.589 8.64964 218.703 7.65758 216.743 7.65758C214.783 7.65758 213.521 8.95277 213.521 11.4329V18.2396H211.48V5.97658H213.521V7.98826C213.892 7.29382 214.441 6.71642 215.108 6.31824C215.775 5.92006 216.535 5.71618 217.307 5.72856C220.046 5.72856 221.63 7.68513 221.63 11.0747V18.2396Z"
					fill={colors.textColor}
				/>
				<path
					d="M233.552 18.4877C230.518 18.4877 229.121 17.8815 227.886 17.2477V14.9879C229.363 15.7871 231.592 16.3383 233.686 16.3383C235.78 16.3383 236.935 15.3738 236.935 13.9683C236.935 12.5629 236.076 11.7637 233.068 11.0472C229.551 10.1654 227.967 8.89777 227.967 6.25226C227.967 3.60675 229.819 1.89819 233.498 1.89819C235.754 1.89819 237.203 2.44934 238.304 3.0556V5.28775C236.803 4.43394 235.107 4.00591 233.391 4.04767C231.323 4.04767 230.115 4.79172 230.115 6.25226C230.115 7.7128 231.108 8.23639 233.901 8.95288C237.203 9.80716 239.083 10.9095 239.083 13.803C239.083 16.6965 237.445 18.4877 233.552 18.4877Z"
					fill={colors.textColor}
				/>
				<path
					d="M244.373 17.468L240.023 5.97656H242.225L246.064 16.4759L249.877 5.97656H252.079L247.568 17.8538C246.225 21.3811 245.474 23.1724 242.413 23.1724C241.392 23.1724 240.828 23.0897 240.238 22.8417V21.0229C240.909 21.2258 241.605 21.3279 242.305 21.326C243.97 21.326 244.722 20.1411 245.688 17.468H244.373Z"
					fill={colors.textColor}
				/>
				<path
					d="M257.368 18.4877C255.931 18.5242 254.505 18.2127 253.207 17.5783V15.3462C254.501 16.1038 255.959 16.5204 257.449 16.5587C259.033 16.5587 259.839 15.8973 259.839 14.9328C259.839 13.9683 259.167 13.4723 256.885 12.8936C254.603 12.3149 253.099 11.185 253.099 9.17332C253.099 7.16163 254.71 5.72864 257.422 5.72864C258.763 5.71397 260.088 6.02562 261.289 6.63804V8.81507C259.839 8.07102 258.684 7.68522 257.422 7.68522C256.16 7.68522 255.086 8.20881 255.086 9.17332C255.086 10.1378 255.677 10.5787 257.879 11.1023C260.51 11.7637 261.852 12.7833 261.852 14.9053C261.852 17.0272 260.564 18.4877 257.368 18.4877Z"
					fill={colors.textColor}
				/>
				<path
					d="M268.968 16.2555C269.402 16.2465 269.834 16.191 270.257 16.0902V18.1294C269.804 18.265 269.333 18.33 268.861 18.3223C266.31 18.3223 265.048 16.7791 265.048 14.4919V7.96076H263.115V5.97663H265.048V3.38623H267.062V5.97663H270.257V7.96076H267.062V14.4367C267.062 15.539 267.679 16.2555 268.968 16.2555Z"
					fill={colors.textColor}
				/>
				<path
					d="M278.098 18.4877C273.64 18.4877 271.68 15.8422 271.68 12.0944C271.68 8.34659 273.694 5.72864 277.319 5.72864C280.944 5.72864 282.474 8.37414 282.474 12.0944V13.0589H273.748C274.043 15.1257 275.359 16.476 278.178 16.476C279.416 16.4867 280.645 16.2625 281.803 15.8146V17.7712C280.836 18.2948 279.333 18.4877 278.098 18.4877ZM273.748 11.2401H280.407C280.272 8.95285 279.279 7.65765 277.238 7.65765C275.198 7.65765 273.962 9.09064 273.748 11.2401Z"
					fill={colors.textColor}
				/>
				<path
					d="M295.067 18.2397H293.026V10.8543C293.026 8.64968 292.167 7.65761 290.207 7.65761C288.247 7.65761 286.985 8.95281 286.985 11.433V18.2397H284.917V5.97661H286.985V7.9883C287.349 7.29403 287.893 6.71598 288.556 6.31743C289.219 5.91888 289.976 5.71517 290.744 5.7286C292.785 5.7286 294.181 6.80334 294.772 8.73235C295.075 7.83301 295.65 7.05652 296.413 6.51617C297.176 5.97582 298.087 5.69988 299.014 5.7286C301.699 5.7286 303.176 7.6025 303.176 11.0747V18.2397H301.135V11.433C301.135 8.8977 300.276 7.65761 298.316 7.65761C296.356 7.65761 295.067 8.95281 295.067 11.433V18.2397Z"
					fill={colors.textColor}
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_134_20771"
					x1="7.67502"
					y1="9.82091"
					x2="2.89395"
					y2="17.8896"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0052CC" />
					<stop offset="0.92" stopColor="#2684FF" />
				</linearGradient>
				<clipPath id="clip0_134_20771">
					<rect width="304" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const ADSLogo = () => {
	const { colorMode } = useThemeObserver();

	return svg(colorMode || 'light');
};
