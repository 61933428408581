import React, { type ComponentType, useState } from 'react';

import { type NewIconProps } from '@atlaskit/icon';
import CopyIcon from '@atlaskit/icon/core/copy';
import { Box, Inline, Pressable, xcss } from '@atlaskit/primitives';

import CopyToClipboard from '../../copy-to-clipboard';

const copyIconstyles = xcss({ flexGrow: 1, textAlign: 'right' });

type CodeIconCopyButtonProps = {
	name: string;
	icon: ComponentType<Omit<NewIconProps, 'spacing'> & { spacing?: 'spacious' }>;
	onClick: () => void;
};

const buttonStyles = xcss({
	textAlign: 'left',
	width: '100%',
	paddingBlock: 'space.075',
	borderRadius: 'border.radius.050',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral',
	},
});

const iconNameStyles = xcss({
	backgroundColor: 'color.background.neutral',
	borderRadius: 'border.radius.050',
	font: 'font.code',
	padding: 'space.050',
});

const iconNameHoverStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
});

const CodeIconCopyButton = ({ name, icon: Icon, onClick }: CodeIconCopyButtonProps) => {
	const [isMouseOver, setIsMouseOver] = useState(false);

	const mouseEnterHandler = () => {
		setIsMouseOver(true);
	};

	const mouseLeaveHandler = () => {
		setIsMouseOver(false);
	};

	return (
		<Pressable
			xcss={buttonStyles}
			onMouseEnter={mouseEnterHandler}
			onMouseLeave={mouseLeaveHandler}
			onClick={() => {
				onClick();
			}}
		>
			<Inline space="space.100" alignBlock="center">
				<Icon label="" spacing="spacious" />
				<Box xcss={[iconNameStyles, isMouseOver && iconNameHoverStyles]}>{name}</Box>
				<Box xcss={copyIconstyles}>
					<CopyIcon spacing="spacious" label="" color="currentColor" />
				</Box>
			</Inline>
		</Pressable>
	);
};

type CodeIconCopyToClipboardProps = {
	value: string;
	name: string;
	icon: ComponentType<Omit<NewIconProps, 'spacing'> & { spacing?: 'spacious' }>;
};

/**
 * __Code icon copy to clipboard__
 *
 * A component that displays an icon, name and a statement which can be copied to the clipboard when clicked.
 */
const CodeIconCopyToClipboard = ({ value, name, icon }: CodeIconCopyToClipboardProps) => {
	return (
		<CopyToClipboard
			value={value}
			messages={{
				prompt: 'Copy migration code',
				success: `Copied "${value}"`,
				fail: 'Copy failed',
			}}
			resetMs={2000}
		>
			{({ copy }) => <CodeIconCopyButton onClick={copy} name={name} icon={icon} />}
		</CopyToClipboard>
	);
};

export default CodeIconCopyToClipboard;
