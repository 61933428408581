import coreIconLabMetadata from '@atlaskit/icon-lab/metadata';
import { metaDataWithPackageLoader as objectMetadata } from '@atlaskit/icon-object/metadata';
import globalMetadata, { coreIconMetadata, utilityIconMetadata } from '@atlaskit/icon/metadata';

import {
	type AllIconsList,
	type IconData,
	type IconPage,
	type IconsList,
	type NewIconData,
	type NewIconsList,
} from './types';

export const categories = ['editor', 'emoji', 'bitbucket', 'jira', 'media-services'];

export const globalCoreIconImportPrefix = '@atlaskit/icon/core';
export const globalUtilityIconImportPrefix = '@atlaskit/icon/utility';
export const iconLabCoreIconImportPrefix = '@atlaskit/icon-lab/core';
export const legacyIconImportPrefix = '@atlaskit/icon/glyph';
export const iconObjectImportPrefix = '@atlaskit/icon-object/glyph';

export const sortIntoCategories = (icons: IconData[], iconType: IconPage) => {
	let categorisedIcons = {} as AllIconsList;
	if (iconType === 'global') {
		categorisedIcons = {
			global: [],
			editor: [],
			emoji: [],
			bitbucket: [],
			jira: [],
			'media-services': [],
		} as AllIconsList;
		icons.forEach((icon) => {
			// example packageName = @atlaskit/icon/glyph/bitbucket/compare
			// index 3 will be 'bitbucket'
			const category = icon.packageName.split('/')[3];
			if (categories.includes(category)) {
				categorisedIcons[category].push(icon);
			} else {
				// packageName could also be a general/global icon = '@atlaskit/icon/glyph/activity'
				categorisedIcons.global.push(icon);
			}
		});
	} else if (iconType === 'object') {
		// e.g. @atlaskit/icon-object/glyph/branch/24
		categorisedIcons = {
			'16': [],
			'24': [],
		} as AllIconsList;
		const sizes = ['16', '24'];
		const numbersRegex = /\d+/g;
		icons.forEach((icon) => {
			const size = icon.componentName.match(numbersRegex)?.[0]; // componentName example = Branch24Icon
			if (size && sizes.includes(size)) {
				categorisedIcons[size].push(icon);
			}
		});
	}
	return categorisedIcons;
};

// example: media-services/vid-play
// should become Vid play
export const getIconName = (name: string) => {
	// remove prefix (e.g. media-services/)
	const nameAsArray = name.split('/');
	if (nameAsArray.length > 1) {
		if (nameAsArray[1] === '16' || nameAsArray[1] === '24') {
			// for object icons: e.g. blog/24, where the number denotes the size
			nameAsArray.pop();
		} else {
			nameAsArray.shift();
		}
	}
	let iconName = nameAsArray.join('');

	// replace any hyphens with spaces
	// and capitalise only the first letter
	if (iconName) {
		iconName = iconName.replace(/-/g, ' ');
		//replaced '-' with /-/g. removes all instances of hyphens rather than just the first
		iconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
	}

	return iconName;
};

// use this to cache the result - this should probs be encapsuated in a signleton class
let globalIconsPromise: Promise<unknown> | null = null;

// get global icon info as a Promise which returns a Record
export const getGlobalIcons = () => {
	if (!globalIconsPromise) {
		globalIconsPromise = Promise.all(
			Object.keys(globalMetadata).map(async (name: string) => {
				const icon = await import(
					/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/glyph/${name}`
				);
				return { name, icon: icon.default };
			}),
		)
			.then((newData) =>
				newData
					.map((icon) => {
						const metadata = globalMetadata[icon.name];
						return {
							[icon.name]: {
								keywords: metadata.keywords,
								component: icon.icon,
								componentName: metadata.componentName,
								packageName: metadata.package,
								iconName: getIconName(icon.name),
							},
						};
					})
					.reduce((acc, b) => ({ ...acc, ...b })),
			)
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error(error);
			});
	}

	return globalIconsPromise;
};

// we use this to cache the result
let objectIconsPromise: Promise<unknown> | null = null;

export const getObjectIcons = () => {
	if (!objectIconsPromise) {
		objectIconsPromise = Promise.all(
			Object.keys(objectMetadata).map(async (name: string) => {
				try {
					const icon = await objectMetadata[name].packageLoader();
					return { name, icon: icon.default };
				} catch (error) {
					throw new Error("Can't find name" + name);
				}
			}),
		)
			.then((newData) =>
				newData
					.map((icon) => {
						const metadata = objectMetadata[icon.name];
						return {
							[icon.name]: {
								keywords: metadata.keywords,
								component: icon.icon,
								componentName: metadata.componentName,
								packageName: metadata.package,
								iconName: getIconName(icon.name),
							},
						};
					})
					.reduce((acc, b) => ({ ...acc, ...b })),
			)
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error(error);
			});
	}
	return objectIconsPromise;
};

export const getAllIcons = async (query: string, iconType: IconPage): Promise<AllIconsList> => {
	const iconsList = iconType === 'global' ? await getGlobalIcons() : await getObjectIcons();

	const icons = filterIcons(iconsList as IconsList, query);

	return sortIntoCategories(icons, iconType);
};

// get core icons info as a Promise which returns a Record
export const getCoreIcons = () => {
	return Promise.all(
		Object.keys(coreIconMetadata).map(async (name: string) => {
			const icon = await import(
				/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/core/${name}`
			);
			return { name, icon: icon.default };
		}),
	)
		.then((newData) =>
			newData
				.map((icon) => {
					const metadata = coreIconMetadata[icon.name];
					return {
						[icon.name]: {
							key: icon.name,
							keywords: metadata.keywords,
							component: icon.icon,
							componentName: metadata.componentName,
							packageName: metadata.package,
							iconName: getIconName(icon.name),
							categorization: metadata.categorization,
							usage: metadata.usage,
							team: metadata.team,
							type: metadata.type,
						} as NewIconData,
					};
				})
				.reduce((acc, b) => ({ ...acc, ...b })),
		)
		.catch((error) => {
			// eslint-disable-next-line no-console
			console.error(error);
		});
};

// get utility icons info as a Promise which returns a Record
export const getUtilityIcons = () => {
	return Promise.all(
		Object.keys(utilityIconMetadata).map(async (name: string) => {
			const icon = await import(
				/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/utility/${name}`
			);
			return { name, icon: icon.default };
		}),
	)
		.then((newData) =>
			newData
				.map((icon) => {
					const metadata = utilityIconMetadata[icon.name];
					return {
						[icon.name]: {
							key: icon.name,
							keywords: metadata.keywords,
							component: icon.icon,
							componentName: metadata.componentName,
							packageName: metadata.package,
							iconName: getIconName(icon.name),
							categorization: metadata.categorization,
							usage: metadata.usage,
							team: metadata.team,
							type: metadata.type,
						} as NewIconData,
					};
				})
				.reduce((acc, b) => ({ ...acc, ...b })),
		)
		.catch((error) => {
			// eslint-disable-next-line no-console
			console.error(error);
		});
};

// get core icons info as a Promise which returns a Record
export const getIconLabCoreIcons = () => {
	return Promise.all(
		Object.keys(coreIconLabMetadata).map(async (name: string) => {
			const icon = await import(
				/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon-lab/core/${name}`
			);
			return { name, icon: icon.default };
		}),
	)
		.then((newData) =>
			newData
				.map((icon) => {
					const metadata = coreIconLabMetadata[icon.name];
					return {
						[icon.name]: {
							key: icon.name,
							keywords: metadata.keywords,
							component: icon.icon,
							componentName: metadata.componentName,
							packageName: metadata.package,
							iconName: getIconName(icon.name),
							categorization: metadata.categorization,
							usage: metadata.usage,
							team: metadata.team,
							type: metadata.type,
						} as NewIconData,
					};
				})
				.reduce((acc, b) => ({ ...acc, ...b })),
		)
		.catch((error) => {
			// eslint-disable-next-line no-console
			console.error(error);
		});
};

export const filterIcons = (
	icons: IconsList | NewIconsList,
	query: string,
): IconData[] | NewIconData[] => {
	const lowerCaseQuery = query.toLowerCase();
	return Object.keys(icons)
		.map((index) => icons[index])
		.filter((icon) =>
			icon.keywords
				.map((keyword) => (keyword.includes(lowerCaseQuery) ? 1 : 0))
				.reduce((allMatches: number, match: number) => allMatches + match, 0),
		);
};

export const getIcon = async (name: string, type: string) => {
	if (type === 'core') {
		const icon = await import(
			/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/core/${name}`
		);
		const metadata = coreIconMetadata[name];
		const data: NewIconData = {
			key: name,
			keywords: metadata.keywords,
			component: icon.default,
			componentName: metadata.componentName,
			packageName: metadata.package,
			iconName: getIconName(name),
			categorization: metadata.categorization,
			usage: metadata.usage,
			team: metadata.team,
			type: metadata.type,
		};
		return data;
	} else if (type === 'utility') {
		const icon = await import(
			/* webpackChunkName: "@atlaskit-internal_[request]" */ `@atlaskit/icon/utility/${name}`
		);
		const metadata = utilityIconMetadata[name];
		const data: NewIconData = {
			key: name,
			keywords: metadata.keywords,
			component: icon.default,
			componentName: metadata.componentName,
			packageName: metadata.package,
			iconName: getIconName(name),
			categorization: metadata.categorization,
			usage: metadata.usage,
			team: metadata.team,
			type: metadata.type,
		};
		return data;
	}
};

export const getMigrationEndpoint = (icon: NewIconData, legacyIconKey: string) => {
	if (icon.key === legacyIconKey) {
		return `@atlaskit/icon/${icon.type}/migration/${icon.key}`;
	} else {
		return `@atlaskit/icon/${icon.type}/migration/${icon.key}--${legacyIconKey.replace('/', '-')}`;
	}
};
