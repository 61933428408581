import underlineBefore from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/underline-before.png";
import underlineAfter from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/underline-after.png";
import colorBefore from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/color-before.png";
import colorAfter from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/color-after.png";
import visitedBefore from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/visited-before.png";
import visitedAfter from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/visited-after.png";
import newWindowBefore from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/new-window-before.png";
import newWindowAfter from "../../../../../../packages/design-system/link/constellation/index/images/migration-guide/new-window-after.png";
import { BeforeAfter } from '@af/design-system-docs-ui';
import SectionMessage from '@atlaskit/section-message';
import * as React from 'react';
export default {
  underlineBefore,
  underlineAfter,
  colorBefore,
  colorAfter,
  visitedBefore,
  visitedAfter,
  newWindowBefore,
  newWindowAfter,
  BeforeAfter,
  SectionMessage,
  React
};