/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { Index } from 'react-instantsearch-dom';

import { MenuGroup } from '@atlaskit/menu';
import { ContextualAnalyticsData, INLINE_DIALOG } from '@atlassian/analytics-bridge';

import Results from './results';

type SearchClientProps = {
	onResultClick?(): void;
	testId?: string;
};

// TODO: Fill in the component {description} and ensure links point to the correct {packageName} location.
// Remove links that the component does not have (such as usage). If there are no links remove them all.
/**
 * __Search client__
 *
 * A search client {description}.
 *
 * - [Examples](https://atlassian.design/components/{packageName}/examples)
 * - [Code](https://atlassian.design/components/{packageName}/code)
 * - [Usage](https://atlassian.design/components/{packageName}/usage)
 */
const SearchClient = ({ onResultClick, testId }: SearchClientProps) => {
	const indices: { name: string; title: string }[] = [
		{ name: 'Components', title: 'Components' },
		{ name: 'Foundations', title: 'Foundations' },
		{ name: 'Patterns', title: 'Patterns' },
		{ name: 'Content', title: 'Content' },
		{ name: 'Brand', title: 'Brand' },
		{ name: 'Resources', title: 'Resources' },
		{ name: 'Get started', title: 'Get started' },
		{ name: 'Tokens', title: 'Tokens' },
	];

	return (
		<React.Fragment>
			{indices.map(({ name }) => (
				<Index key={name} indexName={name} />
			))}

			<ContextualAnalyticsData sourceType={INLINE_DIALOG} sourceName="search">
				<MenuGroup testId={testId ? `${testId}-results` : undefined}>
					<Results onResultClick={onResultClick} />
				</MenuGroup>
			</ContextualAnalyticsData>
		</React.Fragment>
	);
};

export default SearchClient;
